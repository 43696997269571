import { Images } from "./WorkspaceImages";

export const settingsLinks: {
  path: string;
  label: string;
  icon: keyof typeof Images;
}[] = [
  {path: "/workspace/analytics", label: "Go back", icon: "left"},
  { path: "/workspace/settings", label: "Settings", icon: "settingslogo" },
  { path: "/workspace/settings/profile", label: "Profile", icon: "profile" },
  { path: "/workspace/settings/plans", label: "Plans", icon: "plans" },
  { path: "/workspace/settings/about", label: "About", icon: "about" },
  { path: "/workspace/settings/teams", label: "Teams", icon: "teams" },
  {
    path: "/workspace/settings/workspace",
    label: "Workspace",
    icon: "workspace",
  },
  {
    path: "/workspace/settings/notifications",
    label: "Notifications",
    icon: "notification",
  },
];

export const nonSettingsLinks: {
  path: string;
  label: string;
  icon: keyof typeof Images;
}[] = [
  { path: "/workspace/analytics", label: "Analytics", icon: "analyticslogo" },
  { path: "/workspace/zin-ai", label: "Zin AI", icon: "zinAilogo" },
  { path: "/workspace/dashboard", label: "Dashboard", icon: "dashboardsLogo" },
  {
    path: "/workspace/data-connector",
    label: "Data Sources",
    icon: "dataSourcesLogo",
  },
  { path: "/workspace/settings", label: "Settings", icon: "settingslogo" },
];
