import BannerItem from "../components/BannerItem";
import WorkingItem from "../components/WorkingItem";
import WhyUsItem from "../components/WhyUsItem";
import { Images } from "../constants/Images";
import FeatureItem from "../components/FeatureItem";
import "../utils/style.css";
import Header from "../components/Header";
import Carousel from "../components/Carousel";
import Testinomials from "../components/Testinomials";
import Signup from "../components/Signup";
import FAQs from "../components/FAQs";
import Footer from "../components/Footer";
import { Banner } from "../constants/Banner";
import { WhyUs } from "../constants/WhyUs";
import Integration from "../components/Integration";

const Homepage = () => {
  return (
    <div>
      <Header backgroundStyle="" />
      <BannerItem
        content={Banner.home.content}
        image={Images.dashboard}
        button={Banner.home.button}
        page = {"home"}
        backgroundStyle=""
        imageStyle="border: 8px solid #e1f6ff;
        border-radius: 8px;"
      />
      <Carousel />
      <FeatureItem />
      <WhyUsItem WhyUs={WhyUs.home} />
      <WorkingItem />
      <Testinomials />
      <Integration />
      <Signup />
      <FAQs />
      <Footer />
    </div>
  );
};

export default Homepage;
