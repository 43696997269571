import React, { useState, useEffect } from "react";
import mysql from "../assets/images/Intergation_imgs/mysql.svg";
import hubspot from "../assets/images/Intergation_imgs/hubspot.svg";
import gAds from "../assets/images/Intergation_imgs/gAds.svg";
import zapier from "../assets/images/Intergation_imgs/zapier.svg";
import salesforce from "../assets/images/Intergation_imgs/salesforce.svg";
import shopify from "../assets/images/Intergation_imgs/shopify.svg";
import amazonAds from "../assets/images/amazon-logo.svg";
import facebookAds from "../assets/images/facebookAds-logo.svg";

interface Form4Props {
  formData: { selectedCompany: string };
  handleCompanySelect: (selectedCompany: string) => void;
  handleContinue: () => void;
}

const Form4: React.FC<Form4Props> = ({
  formData,
  handleCompanySelect,
  handleContinue,
}) => {
  const { selectedCompany } = formData;
  const [filled, setFilled] = useState(false);
  useEffect(() => {
    const { selectedCompany } = formData;
    if (selectedCompany) {
      setFilled(true);
    } else {
      setFilled(false);
    }
  }, [formData]);
  const companies = [
    {
      id: 1,
      name: "Salesforce",
      logo: salesforce,
    },
    {
      id: 2,
      name: "Zapier",
      logo: zapier,
    },
    {
      id: 3,
      name: "Hubspot",
      logo: hubspot,
    },
    {
      id: 4,
      name: "Google Ads",
      logo: gAds,
    },
    {
      id: 5,
      name: "Shopify",
      logo: shopify,
    },
    {
      id: 6,
      name: "MySql",
      logo: mysql,
    },
    {
      id: 7,
      name: "Amazon Ads",
      logo: amazonAds,
    },
    {
      id: 8,
      name: "Facebook Ads",
      logo: facebookAds,
    },
  ];

  return (
    <div className="onboard flex h-screen justify-center items-center mx-auto drop-shadow-lg">
      <div className="bg-white shadow-lg rounded-2xl p-6 w-[35vw] max-w-md">
        <div className="bg-neutral-400 w-11/12 mx-auto rounded-lg mb-5">
          <div className="progressbar bg-[#4D85D5] w-3/5 h-[5px] rounded-lg"></div>
        </div>
        <div className="my-5">
          <h2 className="text-center text-xl font-semibold text-black">
            Connect to your first data sources
          </h2>
        </div>
        <div className="grid grid-cols-2 gap-5">
          {companies.map((company) => (
            <button
              key={company.id}
              onClick={() => handleCompanySelect(company.name)}
              className={`p-3 rounded-md border flex items-center justify-around ${
                selectedCompany === company.name
                  ? "bg-[#4D85D5] text-white"
                  : "bg-white text-black border-gray-300"
              }`}
            >
              <img
                src={company.logo}
                className="h-8 w-8"
                alt={company.name}
              />
              {company.name}
            </button>
          ))}
        </div>
        <div className="flex items-center justify-between mt-10">
          <button
            onClick={handleContinue}
            type="button"
            className={`${
              filled ? "bg-[#4D85D5]" : "bg-[#4D85D580]"
            } w-[100%] h-[67px] rounded-xl text-lg text-white`}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default Form4;
