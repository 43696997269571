// AiPricingItem.js
import React from "react"; // Importing React
import FeatureComponent from "./FeatureComponent"; // Importing FeatureComponent
import { features } from "../constants/FeatureAianalyst"; // Importing features from constants

const AiPricingItem = () => {
  const FeatureAipricing = features.FeatureAipricing; // Extracting FeatureAipricing from features
  return (
    <div className="flex flex-col m-3 whyUs"> {/* Main container for pricing items */}
      <div className="why"> {/* Section title */}
        <div>Feature</div>
      </div>
      {FeatureAipricing.map((data, ind) => { // Mapping through pricing features
        const isEven = ind % 2 === 0; // Checking if index is even
        const bgClass = isEven ? "even-bg" : "odd-bg"; // Assigning background class based on index
        return (
          <div
            className={`banner flex justify-between items-center text-left why-left-feature ${bgClass}`} // Banner container for each feature
            key={data.id} // Unique key for each item
          >
            {isEven ? ( // Conditional rendering based on index
              <>
                <FeatureComponent feature={data} /> {/* Rendering FeatureComponent for even index */}
                <div className="ai-right flex items-center justify-center"> {/* Image container for even index */}
                  <img
                    className="h-[377px] w-[420px]" // Image styling
                    src={data.img} // Image source
                    alt="" // Alt text for image
                  />
                </div>
              </>
            ) : (
              <>
                <div className="ai-right flex items-center justify-center"> {/* Image container for odd index */}
                  <img
                    className="h-[377px] w-[420px]" // Image styling
                    src={data.img} // Image source
                    alt="" // Alt text for image
                  />
                </div>
                <FeatureComponent feature={data} /> {/* Rendering FeatureComponent for odd index */}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default AiPricingItem; // Exporting the component
