import React, { useState } from "react";
import { plans } from "../constant/Plans";

const PricingPlans = () => {
  const [billingCycle, setBillingCycle] = useState<"monthly" | "annually">(
    "monthly"
  );

  return (
    <div className="max-w-5xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-2">
        Plans for Your Need
      </h1>
      <p className="text-center text-gray-600 mb-8">
        Choose the perfect plan to unlock advanced tools and insights for
        seamless data management.
      </p>

      <div className="flex justify-center mb-8">
        <div className="inline-flex rounded-md shadow-sm" role="group">
          <button
            type="button"
            className={`px-4 py-2 text-sm font-medium rounded-l-lg ${
              billingCycle === "monthly"
                ? "bg-blue-600 text-white"
                : "bg-white text-gray-900 hover:bg-gray-100"
            }`}
            onClick={() => setBillingCycle("monthly")}
          >
            Monthly
          </button>
          <button
            type="button"
            className={`px-4 py-2 text-sm font-medium rounded-r-lg ${
              billingCycle === "annually"
                ? "bg-blue-600 text-white"
                : "bg-white text-gray-900 hover:bg-gray-100"
            }`}
            onClick={() => setBillingCycle("annually")}
          >
            Annually
          </button>
        </div>
      </div>

      <div className="grid md:grid-cols-3 gap-8">
        {plans.map((plan, index) => (
          <div
            key={plan.name}
            className={`rounded-3xl shadow-lg overflow-hidden ${
              index === 1 ? "bg-blue-600 text-white" : "bg-white"
            }`}
          >
            <div className="p-6">
              <img src={plan.logo} alt="" className="h-8 w-8 my-4" />
              <h2
                className={`text-xl font-semibold mb-3 ${
                  index === 1 ? "text-white" : "text-gray-600"
                }`}
              >
                {plan.name}
              </h2>
              <p className="text-sm mb-4">{plan.description}</p>
              <p className="text-4xl font-bold mb-6">
                ${billingCycle === "monthly" ? plan.price : plan.price * 10}
                <span className="text-sm font-normal">
                  /{billingCycle === "monthly" ? "month" : "year"}
                </span>
              </p>
              <button
                className={`w-full py-2 px-4 rounded-xl ${
                  index === 1
                    ? "bg-white text-blue-600 hover:bg-gray-100"
                    : "bg-blue-600 text-white hover:bg-blue-700"
                }`}
              >
                Get Started
              </button>
            </div>
            <div
              className={`px-6 pb-6 py-4 ${
                index === 1 ? "text-white" : "text-gray-600"
              }`}
            >
              <hr className="text-gray-400 mb-6" />
              <h3 className="font-semibold mb-4">Features</h3>
              <ul className="">
                {plan.features.map((feature, featureIndex) => (
                  <li key={featureIndex} className="flex items-center py-1.5">
                    <svg
                      className={`w-4 h-4 mr-2 border-2 rounded-full ${
                        index === 1
                          ? "text-white border-white"
                          : "text-blue-600 border-blue-600"
                      }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      ></path>
                    </svg>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PricingPlans;
