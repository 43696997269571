import { Images } from "./Images";
export const reset = {
    email:{
        title:"Reset Password",
        content:"Enter your email and we’ll send you a link to reset your password.",
        label:"Email ID",
        placeholder:"Enter Here",
        logo:Images.zintellixLogo,
        button:"Send link to email"
    },
    password:{
        title:"Enter New Password",
        content:"Password must contain at least 7 letters and 1 number.",
        label:"Password",
        placeholder:"Enter Here",
        logo:Images.zintellixLogo,
        button:"Reset Password"
    }
}