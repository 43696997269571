const toCamelCase = (str: string) =>
  str.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());

const parseStyles = (styleString: string) => {
  const styleObject: { [key: string]: string } = {};

  styleString.split(";").forEach((style) => {
    const [key, value] = style.split(":").map((item) => item.trim());
    if (key && value) {
      styleObject[toCamelCase(key)] = value; // Convert to camelCase and assign
    }
  });

  return styleObject;
};

export default parseStyles;
