export interface Member {
    id: number;
    name: string;
    email: string;
    role: string;
    joinDate: string;
  }
  
  export interface InvitedMember {
    id: number;
    email: string;
    role: string;
    inviteDate: string;
  }
  
  export const roles = ["Viewer", "Editor", "Owner"];
  
  export const initialMembers: Member[] = [
    {
      id: 1,
      name: "Sheetal Bisht",
      email: "sheetalbisht41@gmail.com",
      role: "Owner",
      joinDate: "1 Aug 2024",
    },
    {
      id: 2,
      name: "Sheetal Bisht",
      email: "sheetalbisht41@gmail.com",
      role: "Viewer",
      joinDate: "1 Aug 2024",
    },
    {
      id: 3,
      name: "Sheetal Bisht",
      email: "sheetalbisht41@gmail.com",
      role: "Editor",
      joinDate: "1 Aug 2024",
    },
  ];