export const analyticsData = [
    { title: "Total Revenue", value: "$1,234,567" },
    { title: "Total Orders", value: "5,678" },
    { title: "CVR", value: "3.45%" },
    { title: "AOV", value: "$217.43" },
  ];

export const ordersComparisonData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
        {
            label: "Regular Orders",
            data: [65, 59, 80, 81, 56, 55],
            borderColor: "rgb(75, 192, 192)",
            backgroundColor: "rgba(75, 192, 192, 0.5)",
        },
        {
            label: "Discounted Orders",
            data: [28, 48, 40, 19, 86, 27],
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
    ],
};

export const customerBreakdownData = {
    labels: ["New Customers", "Repeat Customers"],
    datasets: [
        {
            data: [30, 70],
            backgroundColor: ["rgb(255, 99, 132)", "rgb(54, 162, 235)"],
        },
    ],
};

export const productTableHeaders = [
    "Product Name",
    "Total Sales",
    "Revenue",
    "Actions",
];

export const marketingTableHeaders = [
    "Company Name",
    "Clicks",
    "Conversions",
    "Revenue",
    "ROI",
];

export const productSalesData = [
    { name: "Product A", sales: 1234, revenue: "$12,340", action: "View" },
    { name: "Product B", sales: 2345, revenue: "$23,450", action: "View" },
    { name: "Product C", sales: 3456, revenue: "$34,560", action: "View" },
    { name: "Product D", sales: 4567, revenue: "$45,670", action: "View" },
];

export const marketingData = [
    { company: "Company A", clicks: 1000, conversions: 50, revenue: "$5,000", roi: "250%" },
    { company: "Company B", clicks: 2000, conversions: 100, revenue: "$10,000", roi: "300%" },
    { company: "Company C", clicks: 1500, conversions: 75, revenue: "$7,500", roi: "200%" },
    { company: "Company D", clicks: 3000, conversions: 150, revenue: "$15,000", roi: "400%" },
];



// dashboardConstants.ts

export interface MetricData {
    title: string;
    value: string;
    change: number;
    timeframe: string;
    trendData: number[];
  }
  
  export const metricCardsData: MetricData[] = [
    {
      title: "Total Revenue",
      value: "$1,567.99",
      change: 5.46,
      timeframe: "In Last Month",
      trendData: [3000, 3200, 3100, 3400, 3300, 3700]
    },
    {
      title: "Total Orders",
      value: "5,564.99",
      change: -2.65,
      timeframe: "In Last Month",
      trendData: [500, 520, 480, 510, 530, 550]
    },
    {
      title: "CVR",
      value: "5.5%",
      change: 1.54,
      timeframe: "In Last Month",
      trendData: [5.1, 5.3, 5.0, 5.2, 5.4, 5.5]
    },
    {
      title: "AOV",
      value: "$1,567",
      change: 3.35,
      timeframe: "In Last Month",
      trendData: [1500, 1520, 1540, 1530, 1550, 1567]
    }
  ];
  
  export const salesFunnelData = [
    { name: 'Jul 19', Orders: 65, 'Discounted Orders': 53 },
    { name: 'Jul 26', Orders: 58, 'Discounted Orders': 70 },
    { name: 'Aug 02', Orders: 90, 'Discounted Orders': 58 },
    { name: 'Aug 09', Orders: 81, 'Discounted Orders': 56 },
    { name: 'Aug 16', Orders: 56, 'Discounted Orders': 67 },
    { name: 'Aug 23', Orders: 55, 'Discounted Orders': 77 },
    { name: 'Aug 30', Orders: 40, 'Discounted Orders': 70 },
  ];
  
  export const newVsRepeatData = [
    { name: 'New', value: 161488.24, percentage: 43.55 },
    { name: 'Repeat', value: 36413.33, percentage: 56.45 },
  ];
  
  export const productsData = [
    { name: 'Premium T Shirt', totalSales: 10345, revenue: '$123.33' },
    { name: 'Water Bottle', totalSales: 3446, revenue: '$123.33' },
    { name: 'Water Bottle', totalSales: 3446, revenue: '$123.33' },
    { name: 'Water Bottle', totalSales: 3446, revenue: '$123.33' },
    { name: 'Water Bottle', totalSales: 3446, revenue: '$123.33' },
  ];

  export const lineData = [
    { name: "Week 1", revenue: 1200, orders: 4300, cvr: 3.4, aov: 56 },
    { name: "Week 2", revenue: 2200, orders: 3200, cvr: 3.1, aov: 58 },
    { name: "Week 3", revenue: 1800, orders: 4800, cvr: 5.2, aov: 60 },
    { name: "Week 4", revenue: 1600, orders: 5400, cvr: 4.8, aov: 62 },
  ];