import person from "../assets/images/person.png"
export const Testinomial = [
    {
      "id": 1,
      "span": "Trusted by",
      "heading" : "innovative startups and the world’s leading enterprise",
      "text" : "I've been using Zintellix for several months now, and I can confidently say it has transformed the way our team handles data management  and business intelligence. Zintellix has been a game-changer for our retail analytics and pricing strategies. It has significantly enhanced our business operations. ",
      "name" : "John Doe",
      "company" : "CEO",
      "logo" : person
    },
    {
      "id": 2,
      "span": "Trusted by",
      "heading" : "innovative startups and the world’s leading enterprise",
      "text" : "Lorem In the if type and scrambled it to make a type specimen book. ",
      "name" : "John Doe",
      "company" : "CEO",
      "logo" : person
    },
    {
      "id": 3,
      "span": "Trusted by",
      "heading" : "innovative startups and the world’s leading enterprise",
      "text" : "Lstry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
      "name" : "John Doe",
      "company" : "CEO",
      "logo" : person
    },
  ]

  