import { Images } from "./WorkspaceImages";

export const plans = [
    {
      name: "Basic",
      description: "Essential tools to get started.",
      price: 20,
      features: [
        "3 data sources",
        "1 user account",
        "Limited analytics",
        "Email support",
        "Monthly reports",
        "Basic dashboard",
      ],
      logo:Images.basic,
    },
    {
      name: "Pro",
      description: "Advanced tools for growing teams.",
      price: 120,
      features: [
        "10 data sources",
        "5 user accounts",
        "Advanced analytics",
        "Priority email support",
        "Weekly reports",
        "Customizable dashboard",
      ],
      logo:Images.pro,

    },
    {
      name: "Enterprise",
      description: "Comprehensive solution for large teams.",
      price: 240,
      features: [
        "Unlimited data sources",
        "Unlimited user accounts",
        "Premium analytics",
        "Dedicated 24/7 support",
        "Daily insights",
        "Full dashboard customization",
      ],
      logo:Images.enterprise,
    },
  ];