import React from "react";
import { Working } from "../constants/Working";

const AboutItem = () => {
  const Aboutwhat = Working.Aboutwhat;
  return (
    <>
      <div className="heading">
        <div className="why">
          <div>
            What We <span className="us">Do ?</span>
          </div>
        </div>
      </div>
      <div className="why-grid w-[60%]">
        <div className="grid grid-cols-2">
          {Aboutwhat.map((data, key) => {
            return (
              <div key={key} className="first my-4 bg-white border-rounded w-11/12 items-center rounded-2xl">
                <div className="upper flex text-left flex-col items-start">
                  <img className="w-12 h-12 m-6" src={data.logo} alt={data.alt} />
                  <div className="mx-6 mt-2 mb-1 text-2xl grostesk text-[#2F269D]">
                    {data.heading}
                  </div>
                  <div className="mx-6 text-base my-3 text-[#52606D] onest text-left">
                    {data.text}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default AboutItem;
