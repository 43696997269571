import React from "react";

const Buffering: React.FC = () => {
  return (
    <div className="onboard flex flex-col h-screen justify-center items-center mx-auto drop-shadow-lg">
      <div className="text-xl font-semibold text-black mt-4">
        Setting up your workspace.....
      </div>
      <div className="bg-cyan-700 m-5 w-[20vw] h-[2vh] rounded-lg relative loader-box">
        <div className="loader absolute rounded-lg bg-cyan-400"></div>
      </div>
    </div>
  );
};

export default Buffering;
