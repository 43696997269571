import { useState } from "react";
import { FAQ } from "../constants/FAQ";
import { Images } from "../constants/Images";

const FAQs = () => {
  const [openId, setOpenId] = useState<number | null>(null);

  const toggleMode = (id: number) => {
    setOpenId((prevId) => (prevId === id ? null : id));
  };

  return (
    <div className="FAQ m-[3rem]">
      <div className="why">
        <div className="faq">FREQUENTLY ASKED QUESTIONS</div>
      </div>
      <div className="accordion">
        {FAQ.map((data) => {
          const isOpen = openId === data.id;
          return (
            <div
              className="accordion-item w-full m-6"
              key={data.id}
              onClick={() => toggleMode(data.id)}
            >
              <label
                htmlFor={`question${data.id}`}
                className="accordion-header text-base bg-white flex justify-between items-center text-left cursor-pointer relative p-3"
              >
                <div className="header grotesk pl-4 py-1 transition-all duration-300">
                  {data.prefix} <span className="us">{data.blue}</span>{" "}
                  {data.suffix}
                </div>
                <img
                  className={`h-4 w-4 icon mr-3 transition-transform ${
                    isOpen ? "rotate-45" : ""
                  }`}
                  src={Images.plus}
                  alt="Toggle"
                />
              </label>
              <div
                className={`accordion-content onest text-[#52606D] transition-all duration-300 overflow-hidden ${
                  isOpen ? "h-[100px]" : "h-0"
                }`}
              >
                <hr className="w-11/12 m-auto" />
                <p className="mx-0">{data.text}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FAQs;
