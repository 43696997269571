import React from "react";
import "../utils/style.css";
import Header from "../components/Header";
import Footer from "../components/Footer";

const CookiePolicy = () => {
  return (
    <div>
      <Header backgroundStyle=""/>
      <div className="mt-2 mb-5 mx-5">
        <h1 className="text-center">Cookie Policy</h1>
        <h1>WHAT ARE COOKIES?</h1>
        <p>
          A cookie is a small file that can be placed on your device that allows
          us to recognize and remember you. It is sent to your browser and
          stored on your computer’s hard drive or tablet or mobile device. When
          you visit our sites, we may collect information from you automatically
          through cookies and other tracking technologies.
        </p>
        <h1>WHAT ARE THERE DIFFERENT TYPES OF COOKIES?</h1>
        <h1>First-party and third-party cookies</h1>
        <p>
          There are first-party cookies and third-party cookies. First-party
          cookies are placed on your device directly by us. For example, we use
          first-party cookies to adapt our website to your browser’s language
          preferences and to better understand your use of our website.
          Third-party cookies are placed on your device by our partners and
          service providers. For example, we use third-party cookies to measure
          user numbers on our website or to enable you to share content with
          others across social media platforms.
        </p>
        <h1>Session and persistent cookies</h1>
        <p>
          There are session cookies and persistent cookies. Session cookies only
          last until you close your browser. We use session cookies for a
          variety of reasons, including to learn more about your use of our
          website during one single browser session and to help you to use our
          website more efficiently. Persistent cookies have a longer lifespan
          and aren’t automatically deleted when you close your browser. These
          types of cookies are primarily used to help you quickly sign-in to our
          website again and for analytical purposes.
        </p>
        <h1>WHAT DO WE USE COOKIES FOR?</h1>
        <p>
          Like most providers of online services, we use cookies to provide,
          secure and improve our services, including by remembering your
          preferences, recognizing you when you visit our website and
          personalizing and tailoring ads to your interests. To accomplish these
          purposes, we also may link information from cookies with other
          personal information we hold about you. When you visit our website,
          some or all of the following types of cookies may be set on your
          device.
        </p>
        <h1>Essential website cookies</h1>
        <p>
          These cookies are strictly necessary to provide you with services
          available through our website and to use some of its features, such as
          access to secure areas.
        </p>
        <h1>Analytics cookies</h1>
        <p>
          These cookies help us understand how our website is being used, how
          effective marketing campaigns are, and help us customize and improve
          our websites for you.
        </p>
        <h1>Advertising cookies</h1>
        <p>
          These cookies are used to make advertising messages more relevant to
          you. They perform functions like preventing the same ad from
          continuously reappearing, ensuring that ads are properly displayed for
          advertisers, selecting advertisements that are based on your interests
          and measuring the number of ads displayed and their performance, such
          as how many people clicked on a given ad.
        </p>
        <h1>Social networking cookies</h1>
        <p>
          These cookies are used to enable you to share pages and content that
          you find interesting on our website through third-party social
          networking and other websites. These cookies may also be used for
          advertising By using our website, you consent to the use of these
          cookies. If you do not like the idea of cookies or certain types of
          cookies/beacons, you can change your browser’s settings to delete
          cookies that have already been set and to not accept new cookies. To
          learn more about how to do this, visit the help pages of your browser.
          Please note, however note, that, if you delete cookies or do not
          accept them, you might not be able to use all of the features we
          offer, you may not be able to store your preferences, and some of our
          pages might not display properly
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default CookiePolicy;
