import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Member,
  InvitedMember,
  roles,
  initialMembers,
} from "../../constant/TeamConstants";

const Team: React.FC = () => {
  const [activeTab, setActiveTab] = useState<"members" | "invites">("members");
  const [members, setMembers] = useState<Member[]>(initialMembers);
  const [invitedMembers, setInvitedMembers] = useState<InvitedMember[]>([]);
  const [activeDropdown, setActiveDropdown] = useState<number | null>(null);
  const [activeRoleMenu, setActiveRoleMenu] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState<number | null>(null);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [inviteEmail, setInviteEmail] = useState("");
  const [inviteRole, setInviteRole] = useState("Viewer");

  const notifyCopy = (email: string) => {
    toast.success(`Email copied: ${email}`, { position: "bottom-left" });
  };

  const confirmDelete = (id: number) => {
    setIsModalOpen(true);
    setSelectedMemberId(id);
  };

  const handleDelete = () => {
    if (selectedMemberId !== null) {
      const deletedMember = members.find(
        (member) => member.id === selectedMemberId
      );
      setMembers(members.filter((member) => member.id !== selectedMemberId));
      if (deletedMember) {
        toast.error(`Member deleted: ${deletedMember.name}`, {
          position: "bottom-left",
        });
      }
      setIsModalOpen(false);
      setSelectedMemberId(null);
    }
  };

  const handleRoleChange = (id: number, newRole: string) => {
    const updatedMembers = members.map((member) => {
      if (member.id === id) {
        return { ...member, role: newRole };
      }
      return member;
    });
    setMembers(updatedMembers);
    setActiveRoleMenu(null);
  };

  const handleMoreOptions = (id: number) => {
    setActiveDropdown(id === activeDropdown ? null : id);
  };

  const handleCopyEmail = (email: string) => {
    navigator.clipboard.writeText(email);
    notifyCopy(email);
  };

  const handleInvite = () => {
    if (inviteEmail && inviteRole) {
      const newInvite: InvitedMember = {
        id: Date.now(),
        email: inviteEmail,
        role: inviteRole,
        inviteDate: new Date().toLocaleDateString(),
      };
      setInvitedMembers([...invitedMembers, newInvite]);
      setIsInviteModalOpen(false);
      setInviteEmail("");
      setInviteRole("Viewer");
      toast.success(`Invitation sent to ${inviteEmail}`, {
        position: "bottom-left",
      });
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-8">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold">Team</h1>
        <button
          className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
          onClick={() => setIsInviteModalOpen(true)}
        >
          Invite team members
        </button>
      </div>

      <div className="mt-6">
        <input
          type="text"
          placeholder="Search for team members"
          className="w-1/3 px-4 py-2 border rounded-lg mb-4"
        />
        <div className="border-b border-gray-200">
          <ul className="flex text-sm font-medium">
            <li
              className={`mr-8 pb-2 cursor-pointer ${
                activeTab === "members"
                  ? "border-b-2 border-black"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveTab("members")}
            >
              Members
            </li>
            <li
              className={`pb-2 cursor-pointer ${
                activeTab === "invites"
                  ? "border-b-2 border-black"
                  : "text-gray-500"
              }`}
              onClick={() => setActiveTab("invites")}
            >
              Invites
            </li>
          </ul>
        </div>

        {activeTab === "members" && (
          <div className="mt-4">
            <div className="grid grid-cols-5 gap-4 font-medium mb-2">
              <div>Name</div>
              <div>Email</div>
              <div>Role</div>
              <div>Join Date</div>
              <div>Actions</div>
            </div>
            {members.map((member) => (
              <div
                key={member.id}
                className="grid grid-cols-5 gap-4 items-center py-4 border-b border-gray-200"
              >
                <div className="flex items-center">
                  <div className="w-10 h-10 bg-gray-300 rounded-full flex items-center justify-center">
                    <span className="text-lg text-white">{member.name[0]}</span>
                  </div>
                  <span className="ml-4 font-medium">{member.name}</span>
                </div>
                <div>{member.email}</div>
                <div className="relative">
                  <button
                    className="text-gray-500"
                    onClick={() =>
                      setActiveRoleMenu(
                        activeRoleMenu === member.id ? null : member.id
                      )
                    }
                  >
                    {member.role}
                  </button>
                  {activeRoleMenu === member.id && (
                    <div className="absolute bg-white shadow-lg rounded-lg py-2 mt-2 z-10">
                      {roles.map((role) => (
                        <button
                          key={role}
                          onClick={() => handleRoleChange(member.id, role)}
                          className="flex items-center justify-between px-4 py-2 text-sm w-full text-left hover:bg-gray-100"
                        >
                          <span
                            className={
                              role === member.role
                                ? "text-black"
                                : "text-gray-500"
                            }
                          >
                            {role}
                          </span>
                          {role === member.role && (
                            <svg
                              className="w-4 h-4 text-black"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M5 13l4 4L19 7"
                              />
                            </svg>
                          )}
                        </button>
                      ))}
                    </div>
                  )}
                </div>
                <div className="text-gray-500">{member.joinDate}</div>
                <div className="relative">
                  <button
                    className="text-gray-500"
                    onClick={() => handleMoreOptions(member.id)}
                  >
                    •••
                  </button>
                  {activeDropdown === member.id && (
                    <div className="absolute right-0 bg-white shadow-lg rounded-lg py-2 mt-2 z-10">
                      <button
                        onClick={() => handleCopyEmail(member.email)}
                        className="block px-4 py-2 text-sm text-gray-500 hover:bg-gray-100 w-full text-left"
                      >
                        Copy email ID
                      </button>
                      <button
                        onClick={() => confirmDelete(member.id)}
                        className="block px-4 py-2 text-sm text-red-500 hover:bg-red-100 w-full text-left"
                      >
                        Delete from workspace
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}

        {activeTab === "invites" && (
          <div className="mt-4">
            {invitedMembers.length === 0 ? (
              <div className="text-center py-8">
                <p className="text-gray-500 mb-4">No pending invitations</p>
                <button
                  className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
                  onClick={() => setIsInviteModalOpen(true)}
                >
                  Invite team members
                </button>
              </div>
            ) : (
              <div>
                <div className="grid grid-cols-4 gap-4 font-medium mb-2">
                  <div>Email</div>
                  <div>Role</div>
                  <div>Invite Date</div>
                  <div></div>
                </div>
                {invitedMembers.map((invite) => (
                  <div
                    key={invite.id}
                    className="grid grid-cols-4 gap-4 items-center py-4 border-b border-gray-200"
                  >
                    <div>{invite.email}</div>
                    <div>{invite.role}</div>
                    <div>{invite.inviteDate}</div>
                    <div>
                      <button
                        className="text-red-500 hover:text-red-700"
                        onClick={() => {
                          setInvitedMembers(
                            invitedMembers.filter((m) => m.id !== invite.id)
                          );
                          toast.info(
                            `Invitation to ${invite.email} cancelled`,
                            { position: "bottom-left" }
                          );
                        }}
                      >
                        Cancel Invite
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6">
            <h2 className="text-lg font-medium mb-4">Confirm Deletion</h2>
            <p>Are you sure you want to delete this team member?</p>
            <div className="mt-4 flex justify-end space-x-2">
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 text-sm text-white bg-red-600 hover:bg-red-700 rounded"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      {isInviteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-96">
            <h2 className="text-lg font-medium mb-4">Invite Team Member</h2>
            <div className="flex">

            <div className="flex mb-4">
              <input
                type="email"
                placeholder="Email address"
                className="w-2/3 px-4 py-2 border-l border-t border-b rounded-l-lg"
                value={inviteEmail}
                onChange={(e) => setInviteEmail(e.target.value)}
              />
              <select
                className="w-1/3 px-4 py-2 border rounded-r-lg"
                value={inviteRole}
                onChange={(e) => setInviteRole(e.target.value)}
                aria-label="Select role"
              >
                {roles.map((role) => (
                  <option key={role} value={role}>
                    {role}
                  </option>
                ))}
              </select>
            </div>
          
              </div>
            <p className="text-sm my-2 px-0 text-gray-500">
              Invited people will get an email with a link to join xyz
              workspace.
            </p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setIsInviteModalOpen(false)}
                className="px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleInvite}
                className="px-4 py-2 text-sm text-white bg-blue-600 hover:bg-blue-700 rounded"
              >
                Send Invite
              </button>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default Team;
