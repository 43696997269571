import React, { useState, useEffect } from "react";

interface Form5Props {
  formData: { email1: string; email2: string; email3: string };
  handleFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleContinue: () => void;
  handleSkip: () => void;
}

const Form5: React.FC<Form5Props> = ({
  formData,
  handleFormChange,
  handleContinue,
  handleSkip,
}) => {
  const { email1, email2, email3 } = formData;
  const emailError = ""; // Add your email validation logic if needed

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleContinue();
  };

  const [filled, setFilled] = useState(false);
  const [buttonText, setButtonText] = useState("Continue");

  useEffect(() => {
    if (email1 && email2 && email3) {
      setFilled(true);
      setButtonText("Done");
    } else {
      setFilled(false);
      setButtonText("Continue");
    }
  }, [email1, email2, email3]); // Ensure proper useEffect behavior

  return (
    <div className="onboard flex h-screen justify-center items-center mx-auto drop-shadow-lg">
      <form
        className="w-[35vw] bg-white p-8 rounded-2xl"
        onSubmit={handleSubmit}
      >
        <div className="bg-neutral-400 rounded-lg mb-5">
          <div className="progressbar bg-[#4D85D5] w-4/5 h-[5px] rounded-lg"></div>
        </div>
        <div className="mb-5 text-center">
          <h2 className="text-xl font-semibold text-black my-2">
            Collaborate with your team
          </h2>
          <h6 className="text-sm text-[#5A5D63]">
            Invite your teammates and build better spreadsheets together
          </h6>
        </div>
        <div className="mb-5">
          <label
            className="block text-[#5A5D63] text-sm mb-2 font-normal"
            htmlFor="email1"
          >
            Team member 1
          </label>
          <input
            type="email"
            name="email1"
            id="email1"
            value={email1}
            onChange={handleFormChange}
            className="shadow appearance-none h-11 border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Business Email"
            required
          />
          {emailError && (
            <p className="text-red-500 text-xs italic">{emailError}</p>
          )}
        </div>
        <div className="mb-5">
          <label
            className="block text-[#5A5D63] text-sm mb-2 font-normal"
            htmlFor="email2"
          >
            Team member 2
          </label>
          <input
            type="email"
            name="email2"
            id="email2"
            value={email2}
            onChange={handleFormChange}
            className="shadow appearance-none h-11 border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Business Email"
            required
          />
          {emailError && (
            <p className="text-red-500 text-xs italic">{emailError}</p>
          )}
        </div>
        <div className="mb-5">
          <label
            className="block text-[#5A5D63] text-sm mb-2 font-normal"
            htmlFor="email3"
          >
            Team member 3
          </label>
          <input
            type="email"
            name="email3"
            id="email3"
            value={email3}
            onChange={handleFormChange}
            className="shadow appearance-none h-11 border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Business Email"
            required
          />
          {emailError && (
            <p className="text-red-500 text-xs italic">{emailError}</p>
          )}
        </div>
        <div
          className="skip text-center text-[#383B3D] cursor-pointer mt-4"
          onClick={handleSkip}
        >
          Skip for now {">"}
        </div>
        <div className="flex items-center justify-between mt-10">
          <button
            type="submit"
            className={`${
              filled ? "bg-[#4D85D5]" : "bg-[#4D85D580]"
            } w-[100%] h-[67px] rounded-xl text-lg text-white`}
          >
            {buttonText}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form5;
