import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { profileOptions, notifications } from "../constant/Navbar";
import { Images } from "../constant/WorkspaceImages";

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const notificationRef = useRef<HTMLDivElement>(null);
  const profileRef = useRef<HTMLDivElement>(null);

  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const toggleNotificationDropdown = () => {
    setIsNotificationOpen(!isNotificationOpen);
    setIsProfileOpen(false);
  };

  const toggleProfileDropdown = () => {
    setIsProfileOpen(!isProfileOpen);
    setIsNotificationOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target as Node) &&
        profileRef.current &&
        !profileRef.current.contains(event.target as Node)
      ) {
        setIsNotificationOpen(false);
        setIsProfileOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="bg-white shadow-md p-4 w-full">
      <div className="container flex justify-between items-center">
        {/* Left Section: Logo and Workspace Title */}
        <div className="flex items-center space-x-4">
          <img
            src={Images.zintellixLogo}
            alt="Zintellix Logo"
            onClick={() => navigate("/")}
            className="h-8 cursor-pointer"
          />
          <div className="bg-gray-100 rounded-md px-3 py-1 text-sm font-medium text-gray-700">
            Zintellix Workspace
          </div>
        </div>

        {/* Right Section: Search Box, Notification Icon, and Profile Icon */}
        <div className="flex items-center space-x-4">
          {/* Search Box */}
          <div className="relative">
            <input
              type="text"
              placeholder="Search here..."
              className="bg-gray-100 rounded-full py-2 px-4 pl-10 focus:outline-none focus:ring-2 focus:ring-blue-300"
            />
            <svg
              className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>

          {/* Notification Icon */}
          <div className="relative" ref={notificationRef}>
            <button className="relative" onClick={toggleNotificationDropdown}>
              <img src={Images.notification} className="h-6 w-6" alt="" />
              <span className="absolute top-0 right-0 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                {notifications.length}
              </span>
            </button>

            {/* Notification Dropdown */}
            {isNotificationOpen && (
              <div className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-lg z-10">
                <div className="p-4 border-b flex justify-between items-center">
                  <h3 className="font-medium text-lg">Notifications</h3>
                  <span className="text-blue-500 text-sm">
                    {notifications.length}
                  </span>
                </div>
                <ul className="p-3 space-y-1">
                  {notifications.map((notification) => (
                    <li
                      key={notification.id}
                      className="flex justify-between items-start space-x-3 py-2"
                    >
                      <div className="flex flex-col">
                        <span className="font-sm inter">
                          {notification.message}
                        </span>
                      </div>
                      <div className="text-right text-sm text-gray-500">
                        <span>{notification.time}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          {/* Profile Icon */}
          <div className="relative" ref={profileRef}>
            <img
              src="https://via.placeholder.com/32"
              alt="User Profile"
              className="w-8 h-8 rounded-full cursor-pointer"
              onClick={toggleProfileDropdown}
            />
            {/* Profile Dropdown */}
            {isProfileOpen && (
              <div className="absolute right-0 mt-3 w-60 bg-white rounded-lg shadow-lg z-10">
                <div className="p-2 space-y-1 border-b flex flex-col items-center justify-center">
                  <img
                    src="https://via.placeholder.com/32"
                    alt="User Profile"
                    className="w-8 h-8 mt-1 rounded-full cursor-pointer"
                  />
                  <h4 className="font-medium">Sheetal Bisht</h4>
                  <p className="text-sm text-gray-500">
                    sheetalbisht123@gmail.com
                  </p>
                </div>
                <hr />
                <ul className="p-1">
                  {profileOptions.map((option) => (
                    <li
                      key={option.id}
                      className="flex items-center p-2 space-x-2 cursor-pointer"
                      onClick={() => navigate(option.navigateTo)}
                    >
                      <img
                        src={option.icon}
                        alt={option.label}
                        className="h-4 w-4"
                      />
                      <span>{option.label}</span>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
