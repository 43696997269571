import React, { useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Images } from "../constants/Images";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Registration: React.FC = () => {
  const navigate = useNavigate();

  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    cpassword: "",
  });

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${response.access_token}`,
            },
          }
        );
        // console.log(res);
        await axios.post("http://localhost:5000/api/auth/users", {
          email: res.data.email,
          name: res.data.name,
        });
        toast.success("Account Created");
        navigate("/onboard", { state: { val: res.data } });
      } catch (err) {
        alert(err);
      }
    },
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { email, password, cpassword } = credentials;
    console.log(email,password);
    // toast.success(email);
    if (password !== cpassword) {
      toast.error('Passwords do not match.');
      // toast.error("Passwords do not match");
      return;
    }

    const response = await fetch("http://localhost:5000/api/auth/createuser", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });
    const json = await response.json();
    console.log("json", json);
    if (json.success) {
      // Save the auth token and redirect
      localStorage.setItem("authToken", json.authToken);
      toast.success("Account Created");
      navigate("/login");
    } else {
      toast.error(json.error);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleClick = () => {
    navigate("/login");
  };

  return (
    <div className="bg-1 absolute w-screen h-screen">
      <div className="bg-white grid w-1/3 mt-9 sm:p-8 p-6 shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] rounded-xl overflow-hidden mx-auto">
        <div className="w-11/12">
          <img className="h-10 w-[150px]" src={Images.zintellixLogo} alt="" />
        </div>
        <div className="my-4 w-11/12 px-4 items-left">
          <h3 className="text-xl grotesk font-medium">Sign Up</h3>
          <p className="onest text-[#52606D] ml-0 leading-8 font-normal">
            to create account on Zintellix
          </p>
        </div>
        <div className="px-4 py-2 my-1 flex items-center justify-cente">
          <div
            onClick={() => login()}
            className="w-full px-4 py-2 my-1 text-[#52606D] flex items-center justify-center border-2 border-slate-700 rounded-full"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/1024px-Google_%22G%22_logo.svg.png"
              className="w-6 h-6 mr-5"
              alt=""
            />
            Continue with Google
          </div>
        </div>
        <div className="flex items-center my-2">
          <hr className="w-1/2 text-black" />
          <p className="text-center">OR</p>
          <hr className="w-1/2 mr-auto" />
        </div>
        <form className="w-11/12 items-center mx-auto" onSubmit={handleSubmit}>
          <div className="space-y-5">
            <div>
              <label className="text-sm mb-2 block">Email Id</label>
              <div className="relative flex items-center">
                <input
                  name="email"
                  type="email"
                  required
                  value={credentials.email}
                  onChange={onChange}
                  className="bg-white border border-gray-300 w-full text-sm pl-4 pr-10 py-2.5 rounded outline-blue-500"
                  placeholder="Enter email"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#bbb"
                  stroke="#bbb"
                  className="w-4 h-4 absolute right-4"
                  viewBox="0 0 682.667 682.667"
                >
                  <defs>
                    <clipPath id="a" clipPathUnits="userSpaceOnUse">
                      <path d="M0 512h512V0H0Z" data-original="#000000"></path>
                    </clipPath>
                  </defs>
                  <g
                    clipPath="url(#a)"
                    transform="matrix(1.33 0 0 -1.33 0 682.667)"
                  >
                    <path
                      fill="none"
                      stroke-miterlimit="10"
                      stroke-width="40"
                      d="M452 444H60c-22.091 0-40-17.909-40-40v-39.446l212.127-157.782c14.17-10.54 33.576-10.54 47.746 0L492 364.554V404c0 22.091-17.909 40-40 40Z"
                      data-original="#000000"
                    ></path>
                    <path
                      d="M472 274.9V107.999c0-11.027-8.972-20-20-20H60c-11.028 0-20 8.973-20 20V274.9L0 304.652V107.999c0-33.084 26.916-60 60-60h392c33.084 0 60 26.916 60 60v196.653Z"
                      data-original="#000000"
                    ></path>
                  </g>
                </svg>
              </div>
            </div>
            <div>
              <label className="text-sm mb-2 block">Password</label>
              <div className="relative flex items-center">
                <input
                  name="password"
                  type="password"
                  required
                  value={credentials.password}
                  onChange={onChange}
                  className="bg-white border border-gray-300 w-full text-sm pl-4 pr-10 py-2.5 rounded outline-blue-500"
                  placeholder="Enter password"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#bbb"
                  stroke="#bbb"
                  className="w-4 h-4 absolute right-4 cursor-pointer"
                  viewBox="0 0 128 128"
                >
                  <path
                    d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z"
                    data-original="#000000"
                  ></path>
                </svg>
              </div>
            </div>
            <div>
              <label className="text-sm mb-2 block">Confirm Password</label>
              <div className="relative flex items-center">
                <input
                  name="cpassword"
                  type="password"
                  required
                  value={credentials.cpassword}
                  onChange={onChange}
                  className="bg-white border border-gray-300 w-full text-sm pl-4 pr-10 py-2.5 rounded outline-blue-500"
                  placeholder="Enter password"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#bbb"
                  stroke="#bbb"
                  className="w-4 h-4 absolute right-4 cursor-pointer"
                  viewBox="0 0 128 128"
                >
                  <path
                    d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z"
                    data-original="#000000"
                  ></path>
                </svg>
              </div>
            </div>
            {/* <PasswordChecklist
              rules={["minLength", "specialChar", "number", "capital", "match"]}
              minLength={5}
              value={credentials.password}
              valueAgain={credentials.cpassword}
              onChange={(isValid) => {}}
            /> */}
            <div className="mt-10">
              <button type="submit" className="btn-design w-full h-12">
                Continue
              </button>
            </div>
          </div>
        </form>
        <p className="text-base mt-3 pl-0 text-left text-[#7D7D8A]">
          Already have an account?{" "}
          <button
            onClick={handleClick}
            className="text-blue-600 font-semibold hover:underline ml-1 cursor-pointer"
            type="button"
          >
            Click here
          </button>
        </p>
      </div>
    </div>
  );
};

export default Registration;
