export const ROLE_OPTIONS = [
  "Founder/CEO",
  "Product Manager",
  "Sales Manager",
  "Marketing Manager",
  "Operation Manager",
  "Financial Analyst",
  "HR Manager",
  "Category Manager",
];

export const DEFAULT_PROFILE_IMAGE = "path/to/default-blurred-image.jpg";

// import React, { useState } from "react";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { Dialog } from "@headlessui/react";

// interface Member {
//   id: number;
//   name: string;
//   email: string;
//   role: string;
//   date: string;
// }

// const TeamPage: React.FC = () => {
//   const [members, setMembers] = useState<Member[]>([
//     {
//       id: 1,
//       name: "Sheetal Bisht",
//       email: "sheetalbisht41@gmail.com",
//       role: "Owner",
//       date: "1 Aug 2024",
//     },
//     {
//       id: 2,
//       name: "Sheetal Bisht",
//       email: "sheetalbisht41@gmail.com",
//       role: "Viewer",
//       date: "1 Aug 2024",
//     },
//     {
//       id: 3,
//       name: "Sheetal Bisht",
//       email: "sheetalbisht41@gmail.com",
//       role: "Editor",
//       date: "1 Aug 2024",
//     },
//   ]);

//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedMemberId, setSelectedMemberId] = useState<number | null>(null);

//   const notifyCopy = (email: string) => {
//     toast.success(`Email copied: ${email}`, { position: "bottom-left" });
//   };

//   const confirmDelete = (id: number) => {
//     setIsModalOpen(true);
//     setSelectedMemberId(id);
//   };

//   const handleDelete = () => {
//     if (selectedMemberId !== null) {
//       setMembers(members.filter((member) => member.id !== selectedMemberId));
//       const deletedMember = members.find(
//         (member) => member.id === selectedMemberId
//       );
//       if (deletedMember) {
//         toast.error(`Member deleted: ${deletedMember.name}`, {
//           position: "bottom-left",
//         });
//       }
//       setIsModalOpen(false);
//     }
//   };

//   return (
//     <div className="p-4">
//       <h1 className="text-xl font-bold mb-4">Team</h1>
//       <input
//         type="text"
//         placeholder="Search for team members"
//         className="border rounded-md p-2 w-full mb-4"
//       />

//       <div className="flex mb-4">
//         <button className="px-4 py-2 bg-blue-500 text-white rounded-md">
//           Members
//         </button>
//         <button className="px-4 py-2 bg-gray-100 text-black rounded-md ml-2">
//           Invites
//         </button>
//       </div>

//       <table className="min-w-full bg-white">
//         <thead>
//           <tr>
//             <th className="py-2 px-4 border">Name</th>
//             <th className="py-2 px-4 border">Email</th>
//             <th className="py-2 px-4 border">Role</th>
//             <th className="py-2 px-4 border">Date</th>
//             <th className="py-2 px-4 border">Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {members.map((member) => (
//             <tr key={member.id} className="text-center">
//               <td className="py-2 px-4 border">{member.name}</td>
//               <td className="py-2 px-4 border">{member.email}</td>
//               <td className="py-2 px-4 border">
//                 <select
//                   className="border rounded-md p-1"
//                   defaultValue={member.role}
//                 >
//                   <option value="Owner">Owner</option>
//                   <option value="Viewer">Viewer</option>
//                   <option value="Editor">Editor</option>
//                 </select>
//               </td>
//               <td className="py-2 px-4 border">{member.date}</td>
//               <td className="py-2 px-4 border">
//                 <button
//                   className="px-2 py-1 text-blue-500"
//                   onClick={() => notifyCopy(member.email)}
//                 >
//                   Copy email
//                 </button>
//                 <button
//                   className="px-2 py-1 text-red-500"
//                   onClick={() => confirmDelete(member.id)}
//                 >
//                   Delete
//                 </button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>

//       {/* Delete Confirmation Modal */}
//       <Dialog
//         open={isModalOpen}
//         onClose={() => setIsModalOpen(false)}
//         className="relative z-50"
//       >
//         <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
//         <div className="fixed inset-0 flex items-center justify-center">
//           <Dialog.Panel className="bg-white p-6 rounded-md shadow-lg">
//             <Dialog.Title className="text-lg font-bold">
//               Confirm Delete
//             </Dialog.Title>
//             <Dialog.Description className="py-4">
//               Are you sure you want to delete this member from the team?
//             </Dialog.Description>
//             <div className="flex justify-end">
//               <button
//                 className="mr-4 px-4 py-2 bg-gray-100 rounded-md"
//                 onClick={() => setIsModalOpen(false)}
//               >
//                 Cancel
//               </button>
//               <button
//                 className="px-4 py-2 bg-red-500 text-white rounded-md"
//                 onClick={handleDelete}
//               >
//                 Confirm
//               </button>
//             </div>
//           </Dialog.Panel>
//         </div>
//       </Dialog>

//       {/* React Toastify Container */}
//       <ToastContainer />
//     </div>
//   );
// };

// export default TeamPage;
