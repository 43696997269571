// AiAnalystItem.js
import React from "react"; // Importing React
import FeatureComponent from "./FeatureComponent"; // Importing FeatureComponent
import { features } from "../constants/FeatureAianalyst"; // Importing features from constants

const AiAnalystItem = () => {
  const FeatureAianalyst = features.FeatureAianalyst; // Extracting FeatureAianalyst from features
  return (
    <div className="flex flex-col m-3 whyUs"> {/* Main container */}
      <div className="why"> {/* Section title */}
        <div>Feature</div>
      </div>
      {FeatureAianalyst.map((data, ind) => { // Mapping through features
        const isEven = ind % 2 === 0; // Checking if index is even
        const bgClass = isEven ? "even-bg" : "odd-bg"; // Assigning background class
        return (
          <div
            className={`banner flex justify-between items-center text-left why-left-feature ${bgClass}`} // Banner container
            key={data.id} // Unique key for each item
          >
            {isEven ? ( // Conditional rendering based on index
              <>
                <FeatureComponent feature={data} /> {/* Feature component */}
                <div className="ai-right flex items-center justify-center"> {/* Image container */}
                  <img
                    className="h-[377px] w-[420px]" // Image styling
                    src={data.img} // Image source
                    alt={data.alt} // Image alt text
                  />
                </div>
              </>
            ) : (
              <>
                <div className="ai-right flex items-center justify-center"> {/* Image container */}
                  <img
                    className="h-[377px] w-[420px]" // Image styling
                    src={data.img} // Image source
                    alt={data.alt} // Image alt text
                  />
                </div>
                <FeatureComponent feature={data} /> {/* Feature component */}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default AiAnalystItem; // Exporting the component
