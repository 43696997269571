import { Images } from "./Images";

export const Blogs = [
  {
    id: 1,
    name: "EXECUTIVES & CEOs",
    description:
      "Imagine having real-time performance dashboards and strategic insights at your fingertips. Zintellix help you make informed decisions, predict market trends, and manage risks more effectively.",
    date: "04-07-2024",
    imageUrl: Images.blog1,
    bgStyle: "background: #FFFEF2",
    writer: "John Doe",
    time: "5 min read"
  },
  {
    id: 2,
    name: "Sales Managers",
    description: `'Say goodbye to guesswork! With AI-driven sales forecasting and customer segmentation, you can tailor your strategies and maximize your team's performance.'`,
    date: "04-07-2024",
    imageUrl: Images.blog2,
    bgStyle: "background: #F4F2FF",
    writer: "John Doe",
    time: "5 min read"
  },
  {
    id: 3,
    name: "MARKETING MANAGERS",
    description:
      "Optimize your campaigns and gain deep customer insights. Zintellix analyze past campaign data to boost your ROI and keep you ahead of the competition.",
    date: "04-07-2024",
    imageUrl: Images.blog3,
    bgStyle: "background: #FFF9F4",
    writer: "John Doe",
    time: "5 min read"
  },
];

export const blogDetail = [
  {
    id: 1,
    title: "Blog Post 1",
    content: "This is the full content of blog post 1.",
    imageUrl: "https://via.placeholder.com/150",
  },
  {
    id: 2,
    title: "Blog Post 2",
    content: "This is the full content of blog post 2.",
    imageUrl: "https://via.placeholder.com/150",
  },
  {
    id: 3,
    title: "Blog Post 3",
    content: "This is the full content of blog post 3.",
    imageUrl: "https://via.placeholder.com/150",
  },
];
