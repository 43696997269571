import React, { useEffect, useState } from "react";

interface Form2Props {
  formData: { workspaceName: string };
  handleFormChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleContinue: () => void;
}

const Form2: React.FC<Form2Props> = ({
  formData,
  handleFormChange,
  handleContinue,
}) => {
  const [filled, setFilled] = useState(false);
  useEffect(() => {
    const { workspaceName } = formData;
    if (workspaceName) {
      setFilled(true);
    } else {
      setFilled(false);
    }
  }, [formData]);
  return (
    <div className="onboard flex h-screen justify-center items-center mx-auto drop-shadow-lg">
      <form className="w-[35vw] bg-white p-8 rounded-2xl">
        <div className="bg-neutral-400 rounded-lg mb-5">
          <div className="progressbar bg-[#4D85D5] w-1/5 h-[5px] rounded-lg"></div>
        </div>
        <div className="text text-black">
          Let's roll!
          <br />
          Choose a name for your workspace
        </div>
        <div className="mb-5">
          <input
            type="text"
            name="workspaceName"
            id="workspaceName"
            value={formData.workspaceName}
            onChange={handleFormChange}
            className="shadow appearance-none h-11 border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Workspace Name"
            required
          />
        </div>
        <div className="flex items-center justify-between mt-10">
          <button
            onClick={handleContinue}
            type="button"
            className={`${
              filled ? "bg-[#4D85D5]" : "bg-[#4D85D580]"
            } w-[100%] h-[67px] rounded-xl text-lg text-white`}
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form2;
