import { BannerItemContent } from "../constants/Banner";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import parseStyles from "../utils/ParsingFunction";

// Defining the props interface for BannerItem component
interface BannerItemProps {
  content: BannerItemContent[]; // Array of content items
  image: string; // Image source
  backgroundStyle: string; // Background style string
  imageStyle: string; // Image style string
  button: string; // Button text
  page: string; // Current page identifier
}

// BannerItem functional component
const BannerItem: React.FC<BannerItemProps> = (props) => {
  const { content, image, backgroundStyle, imageStyle, button, page } = props;
  const backgroundStyles = backgroundStyle ? parseStyles(backgroundStyle) : {}; // Parse background styles
  const imageStyles = imageStyle ? parseStyles(imageStyle) : {}; // Parse image styles

  const navigate = useNavigate(); // Hook for navigation

  const [currentTextIndex, setCurrentTextIndex] = useState(0); // State for current text index
  const textOptions = ["Product Team", "Sales Team", "Marketing Team"]; // Text options for display

  // Effect to change text every 3 seconds on home page
  useEffect(() => {
    if (page === "home") {
      const interval = setInterval(() => {
        setCurrentTextIndex(
          (prevIndex) => (prevIndex + 1) % textOptions.length
        );
      }, 3000);
      return () => clearInterval(interval); // Cleanup interval on unmount
    }
  }, [textOptions.length, page]);

  // Function to handle button click
  const HandleClick = () => {
    // navigate("/onboard"); // Navigate to demo page
    navigate("/demo");
  };

  return (
    <div
      className="flex banner ml-5 mr-5 mt-6 mb-5 overflow-hidden justify-between"
      style={backgroundStyles} // Apply background styles
    >
      <div className="flex flex-col banner-left justify-center items-start text-start relative">
        {content.map((data, key) => {
          const styles = parseStyles(data.style); // Parse individual content styles
          if (key === 1 && page === "home") {
            return (
              <div
                key={data.id}
                style={styles}
                className="transition-all transform-gpu ease-in-out duration-500"
              >
                {textOptions[currentTextIndex]}{" "}
                {/* Display current text option */}
              </div>
            );
          }
          return (
            <div key={data.id} style={styles}>
              {data.text} {/* Display content text */}
            </div>
          );
        })}
        {/* to change the text on button depending on the page */}
        <button
          className={`btn-design ${page === "home" ? "new" : "demo"}`}
          onClick={HandleClick} // Handle button click
        >
          {button} {/* Display button text */}
        </button>
      </div>
      <div className="banner-right w-2/3 flex items-center mr-[-180px]">
        <img
          className="dashboard"
          src={image} // Image source
          alt="dashboard" // Alt text for image
          style={imageStyles} // Apply image styles
        />
      </div>
    </div>
  );
};

export default BannerItem; // Exporting the BannerItem component
