import React from 'react';

const AboutSection = () => {
  return (
    <div className="max-w-6xl mx-auto">
      <h1 className="text-2xl font-bold mb-4">About</h1>
      <div className="space-y-4 bg-white p-6 rounded-lg shadow-md">
        <p className="text-lg font-semibold">Welcome to Zintellix!</p>
        <p className="text-gray-700">
          We are dedicated to providing a powerful data management solution designed to help you manage and protect your valuable data efficiently. Our mission is to deliver a seamless experience with features tailored to your needs, including secure backups, real-time alerts, and intuitive integrations.
        </p>
        <p className="text-gray-700">
          Feel free to explore our settings and customize your experience to match your preferences. If you have any questions or need support, our team is here to help!
        </p>
        <p className="text-gray-700">
          For any assistance, you can mail us at{' '}
          <a href="mailto:support@zintellix.com" className="text-blue-600 hover:underline">
            support@zintellix.com
          </a>
        </p>
        <p className="text-gray-700">Thank you for choosing Zintellix.</p>
      </div>
    </div>
  );
};

export default AboutSection;