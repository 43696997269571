import aianalyst from "../assets/images/AiAnalst.png";
import customization from "../assets/images/customization.png";
import report from "../assets/images/reporting.png";
import visual from "../assets/images/visualization.png";
import whyAi  from "../assets/images/Landing Page/1.svg";
import custom  from "../assets/images/Landing Page/2.svg";
import wreport  from "../assets/images/Landing Page/3.svg";
import wvisual  from "../assets/images/Landing Page/4.svg";

export interface whyUsContent {
    id: number;
    heading: string;
    text: string;
    img : string;
    button : string;
    logo : string
  }
  
  export interface whyUsProps {
    content: whyUsContent[];
  }

export const WhyUs = {
    home:[
    {
      "id": 1,
      "heading": "AI Analyst",
      "text" : "Our AI powered agent can learn the performance of your business and provide you instant answers and insights based on your business data",
      "img" : aianalyst,
      "button" : "Book a Demo",
      "logo" : whyAi
    },
    {
        "id": 2,
        "heading": "Customization",
        "text": "The platform allows ecommerce businesses to customize and get insights  based on their specific needs and goals",
        "img" : customization,
        "button" : "Book a Demo",
        "logo" : custom
    },
    {
        "id": 3,
        "heading": "Reporting",
        "text": "The platform  provides clear and actionable reporting that allows ecommerce businesses to track the performance of their business",
        "img" : report,
        "button" : "Book a Demo",
        "logo" : wreport
    },
    {
        "id": 4,
        "heading": "Interactive Visualization",
        "text": "Transform insights into visual representations by generating charts",
        "img" : visual,
        "button" : "Book a Demo",
        "logo" : wvisual
      }
  ],
  aianalyst:[
      {
        "id": 1,
        "heading": "AI That Understands Your Context",
        "text" : "The ability to analyze and interpret the collected data using machine learning algorithms to identify patterns and trends.",
        "img" : aianalyst,
        "button" : "Book a Demo",
        "logo" : whyAi
      },
      {
          "id": 2,
          "heading": "Create Dashboards",
          "text": "The platform allows ecommerce businesses to customize their pricing strategy based on their specific needs and goals",
          "img" : customization,
          "button" : "Book a Demo",
          "logo" : custom
      },
      {
          "id": 3,
          "heading": "Instant Data Decisions",
          "text": "The platform provides clear and actionable reporting that allows ecommerce businesses to track the performance of their pricing strategy over time",
          "img" : report,
          "button" : "Book a Demo",
          "logo" : wreport
      },
      {
          "id": 4,
          "heading": "Your Data Stays On Your Servers",
          "text": "The platform allows ecommerce businesses to customize their pricing strategy based on their specific needs and goals",
          "img" : visual,
          "button" : "Book a Demo",
          "logo" : wvisual
        }
    ],
  aipricing:[
    {
        "id": 1,
        "heading": "AI Analyst",
        "text" : "The ability to analyze and interpret the collected data using machine learning algorithms to identify patterns and trends.",
        "img" : aianalyst,
        "button" : "Book a Demo",
        "logo" : whyAi
      },
      {
          "id": 2,
          "heading": "Customization",
          "text": "The platform allows ecommerce businesses to customize their pricing strategy based on their specific needs and goals",
          "img" : customization,
          "button" : "Book a Demo",
          "logo" : custom
      },
      {
          "id": 3,
          "heading": "Reporting",
          "text": "The platform provides clear and actionable reporting that allows ecommerce businesses to track the performance of their pricing strategy over time",
          "img" : report,
          "button" : "Book a Demo",
          "logo" : wreport
      },
      {
          "id": 4,
          "heading": "Interactive Visualization",
          "text": "The platform allows ecommerce businesses to customize their pricing strategy based on their specific needs and goals",
          "img" : visual,
          "button" : "Book a Demo",
          "logo" : wvisual
        }
  ]
}
  