import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import analystLogo from "../assets/images/Dropdowns/1.svg";
import priceLogo from "../assets/images/Dropdowns/2.svg";
import blogLogo from "../assets/images/Dropdowns/3.svg";
import docsLogo from "../assets/images/Dropdowns/4.svg";
import supportLogo from "../assets/images/Dropdowns/5.svg";

export interface NavbarStyles {
  id: number;
  style: string;
}

export interface NavbarProps {
  content: NavbarStyles[];
}

export const Navbar = [
  {
    id: 1,
    text: "Product",
    logo: KeyboardArrowDownIcon,
    dropdownItems: ["AI Analyst", "AI Pricing"],
    drop: [analystLogo, priceLogo],
    subtitle: ["Advanced Analytics and Insights", "Real-Time Data and Alerts"],
    redirect: ["/aianalyst", "/aiprice"],
    style: ["", "", ""],
  },
  {
    id: 2,
    text: "Use Cases",
    logo: "",
    redirect: "/usage",
  },
  {
    id: 3,
    text: "Resources",
    logo: KeyboardArrowDownIcon,
    dropdownItems: ["Blogs", "Developer Docs", "Support"],
    drop: [blogLogo, docsLogo, supportLogo],
    // "subtitle" : ["Get Insights", "Ask doubts", "Understand platform"],
    subtitle: ["Get Insights", "Coming Soon", "Coming Soon"],
    style: ["", "backgroundColor:#F7D96B", "backgroundColor:#F7D96B"],
    redirect: ["/blogs", "/", "/"],
  },
  {
    id: 4,
    text: "Integrations",
    logo: "",
    redirect: "/integrations",
  },
  {
    id: 5,
    text: "About Us",
    logo: "",
    redirect: "/about",
  },
];
