import postgress from "../assets/images/Intergation_imgs/postress.svg";
import mysql from "../assets/images/Intergation_imgs/mysql.svg";
import amzRedshift from "../assets/images/Intergation_imgs/AmazonRedshift.svg";
import amplitude from "../assets/images/Intergation_imgs/AMPLITUDE.svg";
import gBigQuery from "../assets/images/Intergation_imgs/GoogleBigQuery.svg";
import adobe from "../assets/images/Intergation_imgs/adobe.svg";
import snowflake from "../assets/images/Intergation_imgs/snowflake.svg";
import hubspot from "../assets/images/Intergation_imgs/hubspot.svg";
import gAds from "../assets/images/Intergation_imgs/gAds.svg";
import FBads from "../assets/images/Intergation_imgs/FBads.svg";
import Mixpanel from "../assets/images/Intergation_imgs/Mixpanel.svg";
import zapier from "../assets/images/Intergation_imgs/zapier.svg";
import salesforce from "../assets/images/Intergation_imgs/salesforce.svg";
import Segment from "../assets/images/Intergation_imgs/Segment.svg";
import shopify from "../assets/images/Intergation_imgs/shopify.svg";
import gAnalytics from "../assets/images/Intergation_imgs/gAnalytics.svg";
import klaviyo from "../assets/images/Intergation_imgs/klaviyo.png";
import attentive from "../assets/images/Intergation_imgs/attentive.png";


export interface Company {
  id: number;
  name: string;
  description: string;
  imageUrl: string;
}

export interface Category {
  id: number;
  name: string;
  companyIds: number[];
}

// Example data with categories and descriptions filled
export const companies: Company[] = [
  { id: 1, name: 'Google Analytics', description: 'A widely used web analytics service that tracks and reports website traffic and user behavior.', imageUrl: gAnalytics },
  { id: 2, name: 'Mixpanel', description: 'Mixpanel provides analytics for tracking user interactions with web and mobile applications.', imageUrl: Mixpanel },
  { id: 3, name: 'Amplitude', description: 'Amplitude is a product analytics platform that helps businesses understand user behavior, track customer journeys, and optimize product experiences.', imageUrl: amplitude },
  { id: 4, name: 'Adobe Analytics', description: 'Part of the Adobe Experience Cloud, Adobe Analytics offers advanced web analytics and reporting capabilities.', imageUrl: adobe },
  { id: 5, name: 'Amazon Redshift', description: 'Amazon Redshift is a fully managed, petabyte-scale data warehouse service in the cloud offered by Amazon Web Services (AWS).', imageUrl: amzRedshift },
  { id: 6, name: 'Google BigQuery', description: 'Google BigQuery is a serverless, highly scalable, and cost-effective multi-cloud data warehouse provided by Google Cloud.', imageUrl: gBigQuery },
  { id: 7, name: 'Snowflake', description: 'Snowflake is a cloud-based data warehousing company that provides a platform for data storage, processing, and analytics.', imageUrl: snowflake },
  { id: 8, name: 'Salesforce', description: 'Salesforce provides a comprehensive suite of CRM tools that help businesses manage their customer relationships, sales, marketing, customer service, and more.', imageUrl: salesforce },
  { id: 9, name: 'Segment', description: 'Segment is a customer data platform that helps collect, unify, and route customer data from various sources to different tools and systems.', imageUrl: Segment },
  { id: 10, name: 'HubSpot', description: 'HubSpot offers a comprehensive platform that includes tools for inbound marketing, sales, customer service, and CRM.', imageUrl: hubspot },
  { id: 11, name: 'Zapier', description: 'Zapier is a tool that connects different web applications and automates workflows between them.', imageUrl: zapier },
  { id: 12, name: 'Google Ads', description: 'Google Ads is an online advertising platform developed by Google where advertisers bid to display brief advertisements, service offerings, product listings, or videos to web users.', imageUrl: gAds },
  { id: 13, name: 'Facebook Ads', description: 'Facebook Ads is an advertising platform that allows businesses to create and display ads to users on Facebook and its associated platforms.', imageUrl: FBads },
  { id: 14, name: 'Shopify', description: 'Shopify is a leading e-commerce platform that allows businesses to create and manage online stores.', imageUrl: shopify },
  { id: 15, name: 'PostgreSQL', description: 'PostgreSQL is a powerful, open-source object-relational database system that uses and extends the SQL language.', imageUrl: postgress },
  { id: 16, name: 'MySQL', description: 'MySQL is one of the most popular open-source relational database management systems.', imageUrl: mysql },
  { id: 17, name: 'Klaviyo', description: 'Klaviyo is a powerful email and SMS marketing platform designed to help businesses of all sizes manage and optimize their customer communications.', imageUrl: klaviyo },
  { id: 18, name: 'Attentive', description: 'Attentive is a leading SMS marketing platform focused on helping businesses engage with their customers through personalized text messaging.', imageUrl: attentive },
];

export const categories: Category[] = [
  { id: 1, name: 'Analytics', companyIds: [1, 2, 3, 4] },
  { id: 2, name: 'Data Warehouse', companyIds: [5, 6, 7] },
  { id: 3, name: 'CRM', companyIds: [8] },
  { id: 4, name: 'Customer Data Platform', companyIds: [9] },
  { id: 5, name: 'Marketing', companyIds: [10, 11, 12, 13,17,18] },
  { id: 6, name: 'Ecommerce', companyIds: [14] },
  { id: 7, name: 'RDBMS', companyIds: [15, 16] },
];