import React from "react";

const FeatureComponent = ({ feature }) => {
  return (
    <div className="flex flex-col w-full px-4 sm:px-6 md:w-1/2 md:ml-10">
      <div className="font-medium text-[#2F269D] bg-[#ffffff] w-full sm:w-2/3 md:w-1/2 mb-5 py-2 text-center rounded-full">
        {feature.featureType}
      </div>
      <div className="upper-down">
        {feature.headings.map((heading, index) => (
          <div key={index} className="mb-6 text-left">
            <div className="flex items-center">
              <div className="rounded-full bg-white w-8 h-8 sm:w-9 sm:h-9 flex justify-center items-center mr-3 border-4 border-[#8BBCF2] flex-shrink-0">
                <img
                  className="w-4 h-4 sm:w-5 sm:h-5 bg-white"
                  src={feature.logos[index]}
                  alt=""
                />
              </div>
              <div className="font-medium text-lg sm:text-xl text-[#282828]">
                {heading}
              </div>
            </div>
            <div className="mt-2 text-sm sm:text-base font-normal text-[#52606D]">
              {feature.texts[index]}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeatureComponent;
