import one from "../assets/images/usage/1.svg";
import two from "../assets/images/usage/02.svg";
import three from "../assets/images/usage/03.svg";
import four from "../assets/images/usage/04.svg";
import five from "../assets/images/usage/05.svg";
import six from "../assets/images/usage/06.svg";
import seven from "../assets/images/usage/07.svg";
import eight from "../assets/images/usage/08.svg";

export interface Company {
  id: number;
  name: string;
  description: string;
  imageUrl: string;
  bgStyle: string;
}

export interface Category {
  id: number;
  name: string;
  companyIds: number[];
}

// Example data with categories and descriptions filled
export const companies: Company[] = [
  {
    id: 1,
    name: "EXECUTIVES & CEOs",
    description:
      "Imagine having real-time performance dashboards and strategic insights at your fingertips. Zintellix help you make informed decisions, predict market trends, and manage risks more effectively.",
    imageUrl: one,
    bgStyle: "background: #FFFEF2",
  },
  {
    id: 2,
    name: "Sales Managers",
    description: `Say goodbye to guesswork! With AI-driven sales forecasting and customer segmentation, you can tailor your strategies and maximize your team's performance.`,
    imageUrl: two,
    bgStyle: "background: #F4F2FF",
  },
  {
    id: 3,
    name: "MARKETING MANAGERS",
    description:
      "Optimize your campaigns and gain deep customer insights. Zintellix analyze past campaign data to boost your ROI and keep you ahead of the competition.",
    imageUrl: three,
    bgStyle: "background: #FFF9F4",
  },
  {
    id: 4,
    name: "Financial Analysts",
    description:
      "From accurate financial forecasting to anomaly detection, Zintellix  ensure your financial planning is spot-on. Plus, they help you evaluate investment opportunities with precision.",
    imageUrl: four,
    bgStyle: "background: #F4FFFD",
  },
  {
    id: 5,
    name: "Operations Managers",
    description:
      "Streamline your processes and manage your supply chain with ease. AI-driven analytics identify inefficiencies and predict demand, ensuring smooth operations.",
    imageUrl: five,
    bgStyle: "background: #F6FCFF",
  },
  {
    id: 6,
    name: "Category Managers",
    description:
      "Do Pricing Analysis and check the success of your pricing strategies. Automatically adjust prices for ecommerce businesses based on the recommendations provided by the AI model.",
    imageUrl: six,
    bgStyle: "background: #FCF1FF",
  },
  {
    id: 7,
    name: "Product Managers",
    description:
      "Reveal the real-world attributes of your customer base. Create better growth strategies and product roadmap by understanding attributes like Age, Income, Consumer Interests, and over 100 categories to inform strategy",
    imageUrl: seven,
    bgStyle: "background: #FFF8F9",
  },
  {
    id: 8,
    name: "Customer Service Managers",
    description:
      "Elevate your customer experience. Analyze feedback, monitor performance metrics, and integrate AI chatbots to handle routine inquiries efficiently.",
    imageUrl: eight,
    bgStyle: "background: #F2FFF1",
  },
];

export const categories: Category[] = [
  { id: 1, name: "Executives/CEOs", companyIds: [1] },
  { id: 2, name: "Sales Managers", companyIds: [2] },
  { id: 3, name: "Marketing Managers", companyIds: [3] },
  { id: 4, name: "Financial Analysts", companyIds: [4] },
  { id: 5, name: "Operation Managers", companyIds: [5] },
  { id: 6, name: "HR Managers", companyIds: [6] },
  { id: 7, name: "Product Managers", companyIds: [7] },
  { id: 8, name: "Custom Service Managers", companyIds: [8] },
];
