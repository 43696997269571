import React from "react";
import Reviews from "./Reviews";

const Testimonials = () => {
  return (
    <section className="py-8 mb-40 lg:py-15 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-16 items-center">
          <div className="text-center lg:text-left">
            <div className="grotesk text-2xl sm:text-3xl md:text-4xl lg:text-5xl">
              <span className="us block lg:inline">Trusted by</span> innovative
              <br className="hidden lg:block" /> startups and the
              <br className="hidden lg:block" /> world's leading
              <br className="hidden lg:block" /> enterprise
            </div>
          </div>
          <div className="w-full max-w-xl mx-auto lg:max-w-none">
            <Reviews />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
