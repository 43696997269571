import { Working } from "../constants/Working";

import React from "react";

const WorkingItem = () => {
  const working = Working.working;
  return (
    <>
      <div className="heading">
        <div className="why">
          <div>
            How Zintellix <span className="us">Works ?</span>
          </div>
        </div>
      </div>
      <div className="why-grid w-4/5">
        <div className="grid grid-cols-2">
          {working.map((data, key) => {
            return (
              <div key={key} className="first m-4 p-6">
                <div className="upper flex items-center text-left">
                  <img className="how-logo" src={data.logo} alt={data.alt} />
                  <div className="how-heading">{data.heading}</div>
                </div>
                <div className="how-content why-content onest text-left">
                  {data.text}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default WorkingItem;
