// src/constant/navbar.ts
import { Images } from "./WorkspaceImages";
export const profileOptions = [
    {
      id: 1,
      icon: Images.settingslogo,
      label: "Settings",
      navigateTo: "/workspace/settings",
    },
    {
      id: 2,
      icon: Images.plans,
      label: "Upgrade Plan",
      navigateTo: "/workspace/settings/plans",
    },
    {
      id: 3,
      icon: Images.docs,
      label: "Docs",
      iconPath: "/path/to/docs/icon.png", // Optional icon
      navigateTo: "/workspace/settings/profile",
    },
    {
      id: 4,
      icon: Images.about,
      label: "Support",
      navigateTo: "/workspace/settings/about",
    },
    {
      id: 5,
      icon: "logout",
      label: "Logout",
      navigateTo: "/logout", // You can replace this with a logout handler
    },
  ];
  
  export const notifications = [
    {
      id: 1,
      message: "📈 Your store’s revenue increased by 12% this week compared to the last!",
      time: "2m",
    },
    {
      id: 2,
      message: "Sales alert! You’ve reached 80% of your monthly revenue target.",
      time: "22m",
    },
    {
      id: 3,
      message: "💡 Your latest marketing campaign has driven 300 new visitors to your site!",
      time: "1hr",
    },
    {
      id: 4,
      message: "📉 Low-performing product: Product Y hasn’t sold in 7 days.",
      time: "12hr",
    },
    {
      id: 5,
      message: "📊 Campaign Performance: Your Google Ads campaign has a 20% lower conversion rate than last week.",
      time: "15hr",
    },
  ];
  