import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../utils/style.css";
import Testinomials from "../components/Testinomials";
import parseStyles from "../utils/ParsingFunction";

import {
  categories,
  Category,
  companies,
  Company,
} from "../constants/UseCases";

const UseCases: React.FC = () => {
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]); // State to track selected categories
  const [searchQuery, setSearchQuery] = useState(""); // State to track search query

  // Function to handle category selection
  const handleCategoryChange = (categoryId: number) => {
    setSelectedCategories((prevSelected) =>
      prevSelected.includes(categoryId)
        ? prevSelected.filter((id) => id !== categoryId) // Remove category if already selected
        : [...prevSelected, categoryId] // Add category if not selected
    );
  };

  // Function to handle search input change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value); // Update search query state
  };

  // Function to filter companies based on selected categories and search query
  const filterCompanies = (): Company[] => {
    let filteredCompanies = companies; // Start with all companies
    if (selectedCategories.length > 0) {
      // If there are selected categories, filter companies
      const selectedCompanyIds = selectedCategories.flatMap(
        (categoryId) =>
          categories.find((cat) => cat.id === categoryId)?.companyIds || [] // Get company IDs for selected categories
      );
      filteredCompanies = filteredCompanies.filter((company) =>
        selectedCompanyIds.includes(company.id) // Filter companies by selected IDs
      );
    }

    if (searchQuery) {
      // If there is a search query, filter companies by name
      filteredCompanies = filteredCompanies.filter((company) =>
        company.name.toLowerCase().includes(searchQuery.toLowerCase()) // Case-insensitive search
      );
    }

    return filteredCompanies; // Return the filtered list of companies
  };

  const filteredCompanies = filterCompanies(); // Get the filtered companies based on current state

  return (
    <div>
      <Header backgroundStyle="background:linear-gradient(96.3deg, #FEF3ED 0%, #E8F4FF 100.39%)" />
      <div className="about-banner flex flex-col items-center h-[50vh]">
        <div className="heading items-center mt-5">
          Ways to use {" "} <span className="us"> Zintellix</span>
        </div>
        <div className=" text-lg text-[#52606D] onest my-3">
          Empower every team member with good data analysis and insights
        </div>
        <button className="demo btn-design my-8">Book a Demo</button>
      </div>
      <div className="use m-10 content-center text-3xl grotesk">
        <div>How Professionals Use Zintellix?</div>
      </div>

      {/* Search Bar Section */}
      <div className="search-bar m-10">
        <input
          type="text"
          placeholder="Search companies..."
          className="p-2 border border-gray-300 rounded-md w-full"
          value={searchQuery}
          onChange={handleSearchChange} // Connect search input
        />
      </div>

      {/* Categories Section */}
      <div className="categories m-10">
        <ul className="flex flex-wrap">
          {categories.map((category: Category) => (
            <li key={category.id} className="flex items-center">
              <div className="bg-white rounded-full p-2">
                <span
                  className={`cursor-pointer ${
                    selectedCategories.includes(category.id)
                      ? "font-semibold"
                      : "text-[#52606D]"
                  }`}
                  onClick={() => handleCategoryChange(category.id)}
                >
                  {category.name}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Filtered Companies Section */}
      <div className="flex-1 p-4 m-10 overflow-y-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-12">
          {filteredCompanies.map((company: Company) => {
            const styles = parseStyles(company.bgStyle);
            return (
              <div
                key={company.id}
                style={styles}
                className="group bg-white flex border h-[30vh] border-gray-300 rounded-2xl p-4 shadow transition-transform transform hover:scale-104 overflow-hidden"
              >
                <div>
                  <div className="l w-2/3">
                    <div className="text-[#2F269D] grotesk text-xl pl-4 m-3">
                      {company.name}
                    </div>
                    <div className="text-[#52606D] onest text-base pl-4 my-4 mx-3">
                      {company.description}
                    </div>
                  </div>
                  <div className="r w-1/3">
                    <img
                      src={company.imageUrl}
                      alt={company.name}
                      className="m-auto relative w-20 align-middle h-20 object-contain"
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Testinomials />
      <Footer />
    </div>
  );
};

export default UseCases;
