import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AboutItem from "../components/AboutItem";
import mission from "../assets/images/mission_img.png";
import vision from "../assets/images/vision_img.png";

const About = () => {
  return (
    <div>
      <Header backgroundStyle="background:linear-gradient(96.3deg, #FEF3ED 0%, #E8F4FF 100.39%)" />
      <div className="about-banner flex lg:flex-row justify-around sm:flex-col sm:items-center">
        <div className="left py-5 heading flex flex-col items-center">
          Welcome to <span className="us">Zintellix</span>
        </div>
        <div className="w-1/2 text-lg text-[#52606D] items-center content-center">
          At Zintellix, we are committed to revolutionizing the way businesses
          harness the power of data. Founded by a team of visionary
          technologists and data scientists, Zintellix is at the forefront of
          delivering cutting-edge data analytics that empower organizations to
          make informed, strategic decisions.
        </div>
      </div>
      <AboutItem />
      <div className="mission flex justify-around mx-6 my-12">
        <div className="left items-center content-center">
          <div className="heading">
            Our <span className="us">Mission</span>{" "}
          </div>
          <div className="content text-[20px] text-[#52606D]">
            Our mission is to simplify data complexity, making advanced
            analytics and actionable insights accessible to all businesses. We
            believe that every enterprise should have the tools to leverage
            their data effectively. Our goal is to bridge the gap between data
            and decision-making, driving growth and innovation for our customers
          </div>
        </div>
        <div className="right">
          <img src={mission} alt="" className="w-[526px] h-[448px]" />
        </div>
      </div>
      <div className="vision flex justify-around m-6">
        <div className="right">
          <img src={vision} alt="" className="w-[526px] h-[448px]" />
        </div>
        <div className="left items-center content-center">
          <div className="heading">
            Our <span className="us">Vision</span>{" "}
          </div>
          <div className="content text-[20px] text-[#52606D]">
            We are passionate about exploring new technologies and methodologies
            to deliver the most effective data analytics platform. We believe AI
            will be the driving factor for growth for E-commerce companies. With
            our tools companies can increase their revenue and reduce cost
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center">
        <div className="text-[48px] m-5">
          Join Us on the Journey to <span className="us">Data Excellence</span>
        </div>
        <div className="w-1/2 mt-4 mb-9 text-[#52606D] text-center">
          We are your partners in navigating the complex data landscape, helping
          you unlock the full
          <br />
          potential of your data. Join us on the journey to data excellence and
          experience the
          <br /> transformative power of data-driven decision-making.
          <br />
          For more information or to discuss how we can support your data needs,
          please contact us at
          <br />{" "}
          <a href="mailto:hello@zintellix.com" className="us cursor-pointer">
            hello@zintellix.com
          </a>{" "}
          . Together, let's turn your data into a strategic asset.
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
