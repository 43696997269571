export const FAQ = [
    {
      id: 1,
      prefix: "Who is ",
      blue : "Zintellix ",
      suffix : "built for?",
      text : "Zintellix is built for data and analytics team, useful for everyone. Zintellix helps data teams organize and label their data so it's ready for LLM ingestion. All organized data products are usable by anyone who has a data question, regardless of their technical skills."
    },
    {
      id: 2,
      prefix: "How does ",
      blue : "Zintellix ",
      suffix : "work?",
      text : "Zintellix uses large lanuage models (LLMs) to query your data and pull insights from this data through our platform."
    },
    {
      id: 3,
      prefix: "How do you ensure the ",
      blue : "security ",
      suffix : "of my data?",
      text : "We prioritize your data's security and privacy. To ensure its protection, we employ industry-leading encryption protocols for your data, both when it's transmitted and while it's stored"
    },
    {
      id: 4,
      prefix: "What platform does ",
      blue : "Zintellix",
      suffix : "integrate with?",
      text : "Currently we offer integrations across standalone files, databases, and third parties such as Google Analytics. If you are looking for an integration we do not currently provide, we are happy to create a new integration for our enterprise offering."
    },
  ];