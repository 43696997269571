import Form from "../components/Form";
import { Feature } from "../constants/Feature";
import "../utils/style.css";
import Testinomials from "../components/Testinomials";
import Footer from "../components/Footer";
import Carousel from "../components/Carousel";
import Header from "../components/Header";

const DemoPage = () => {
  return (
    <div>
      <Header backgroundStyle="" />
      <div className="flex md:flex-row sm:flex-col text-left justify-around mt-22 p-10 demoPage mb-[11rem]">
        <div className="md:flex-start sm:items-center w-1/2 ml-6">
          <div className="text-5xl font-normal">
            Book <span className="us">free</span> demo now
          </div>
          <div className="text-base font-normal onest mt-6 mb-12 w-3/4">
            Tell us a little about yourself, and we'll connect you with a
            superchat expert who can tell you more about the product and answer
            your questions.
          </div>
          <div className="features">
            <div className="text-base font-normal onest mt-6 mb-8">
              Our features :
            </div>
            {Feature.map((data,key) => {
              return (
                <div key={key} className="flex mt-4">
                  <img className="mr-5 h-6 w-6" src={data.logo} alt="" />
                  <div className="font-normal text-xl onest">
                    {data.heading}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="right">
          <Form />
        </div>
      </div>
      <Carousel />
      <Testinomials />
      <Footer />
    </div>
  );
};

export default DemoPage;
