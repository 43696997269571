export interface BannerItemContent {
  id: number;
  text: string;
  style: string;
}

export interface BannerProps {
  content: BannerItemContent[];
  button: string;
}

export const Banner = {
  home: {
    content: [
      {
        id: 1,
        text: " E Commerce AI Analyst for",
        style: `
        font-family: "Schibsted Grotesk", sans-serif;
        line-height: 70.7px;
        font-size: 64px;
        `,
      },
      {
        id: 2,
        text: "Product Team",
        style: `
          Schibsted Grotesk", sans-serif;
          line-height: 70.7px;
          font-size: 64px;
          color:#2f269d`,
      },
      {
        id: 3,
        text: "Automate your Analytics and get insights.",
        style: `max-width: 31rem;
          font-family: "Onest", sans-serif;
          margin: 1.5rem 0 0 0;
          color: #52606D;
          font-size:18px;
          `,
      },
      {
        id: 4,
        text: "No Coding Required",
        style: `max-width: 31rem;
          font-family: "Onest", sans-serif;
          margin: 0 0 2rem 0;
          color: #52606D;
          font-size:18px;
          `,
      },
    ],
    button: "Get AI Powered Insights",
  },
  aianalyst: {
    content: [
      {
        id: 1,
        text: "AI Analyst",
        style: `
        font-family: "Schibsted Grotesk", sans-serif;
        line-height: 70.7px;
        font-size: 64px;
        `,
      },
      {
        id: 2,
        text: "AI Analysts Empowering E-commerce Teams. Drive Growth and Efficiency Across Product, Marketing, Sales, and Ops",
        style: `max-width: 31rem;
        font-family: "Onest", sans-serif;
        color: #52606D;
        margin: 2rem 0 2rem 0;`,
      },
    ],
    button: "Book a Demo",
  },
  aipricing: {
    content: [
      {
        id: 1,
        text: "AI Pricing",
        style: `
        font-family: "Schibsted Grotesk", sans-serif;
        line-height: 70.7px;
        font-size: 64px;
        `,
      },
      {
        id: 2,
        text: "AI Analysts Empowering E-commerce Teams. Drive Growth and Efficiency Across Product, Marketing, Sales, and Ops",
        style: `max-width: 31rem;
        font-family: "Onest", sans-serif;
        color: #52606D;
        margin: 2rem 0 2rem 0;`,
      },
    ],
    button: "Book a Demo",
  },
};
