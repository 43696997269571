import React, { useState } from "react";
import "tailwindcss/tailwind.css";
import { useNavigate } from "react-router-dom";
const Billing = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  // Modal toggle handler
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="p-8">
      {/* Header section */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold">Plans & Billing</h1>
        <button
          className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700"
          onClick={() => navigate("/workspace/upgrade-plan")}
        >
          Upgrade
        </button>
      </div>

      <div className="rounded-lg">
        {/* Payment method section */}
        <div className="flex justify-between bg-white p-6 shadow-md">
          <div className="md:w-1/2 w-full">
            <div className="text-lg inter font-medium mb-4">Payment method</div>
            <div className="inter text-[#5A5D63]">
              Change how you pay for your plan
            </div>
          </div>
          <div className="flex justify-between border items-center md:w-1/2 w-full">
            <div className="p-4">
              <span className="inline-block bg-gray-300 px-3 py-1 rounded-md text-gray-800 mr-4">
                CARD
              </span>
              <span className="text-gray-600">No cards available</span>
            </div>
            <div className="px-4">
              <button
                className="border text-black-300 px-4 py-2 rounded-md hover:bg-blue-700"
                onClick={toggleModal}
              >
                + Add Card
              </button>
            </div>
          </div>
        </div>
        <hr />
        {/* Change plan section */}
        <div className="bg-white p-6 shadow-md flex justify-between">
          <div>
            <div className="inter text-lg font-medium mb-4">Change plan</div>
            <div className="inter text-[#5A5D63]">
              Upgrade your current plan.
            </div>
            <button className="text-blue-500" onClick={() => {/* Add your navigation logic here */}}>
              know more
            </button>
          </div>
          <div className="flex justify-between border items-center md:w-1/2 w-full">
            <div className="p-4 flex-col">
              <span className="px-3 py-1 rounded-md text-gray-800 mr-4">
                Trial ends in 16 days
              </span>
            </div>
            <div className="px-4">
              <button
                className="border text-black-300 px-4 py-2 rounded-md hover:bg-blue-700"
                onClick={() => navigate("/workspace/upgrade-plan")}
              >
                Manage Plan
              </button>
            </div>
          </div>
        </div>
        <hr />
        {/* Billing history section */}
        <div className="col-span-2 bg-white p-6 shadow-md flex justify-between">
          <div className="a">
            <div className="inter text-lg font-medium mb-4">
              Billing history
            </div>
          </div>
          <div className="b">
            <div className="overflow-x-auto">
              <table className="min-w-full text-left">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="px-4 py-2">Invoice</th>
                    <th className="px-4 py-2">Status</th>
                    <th className="px-4 py-2">Amount</th>
                    <th className="px-4 py-2"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-gray-50">
                    <td className="px-4 py-2">Jul, 2024</td>
                    <td className="px-4 py-2">
                      <span className="bg-green-100 text-green-700 px-3 py-1 rounded-full">
                        Paid
                      </span>
                    </td>
                    <td className="px-4 py-2">$0.00</td>
                    <td className="px-4 py-2">
                      <button className="text-blue-600 hover:underline" onClick={() => {/* Add your download logic here */}}>
                        Download
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for adding card */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white rounded-lg shadow-lg p-6 w-1/3">
            <h2 className="text-xl font-semibold mb-4">Edit Payment method</h2>
            <form>
              <div className="mb-4">
                <label className="block text-gray-700">Card number</label>
                <input
                  type="text"
                  placeholder="1234 1234 1234 1234"
                  className="w-full px-3 py-2 border rounded-md"
                />
              </div>
              <div className="flex justify-between mb-4">
                <div className="w-1/2 mr-2">
                  <label className="block text-gray-700">Expiration date</label>
                  <input
                    type="text"
                    placeholder="MM / YY"
                    className="w-full px-3 py-2 border rounded-md"
                  />
                </div>
                <div className="w-1/2 ml-2">
                  <label className="block text-gray-700">Security code</label>
                  <input
                    type="text"
                    placeholder="CVC"
                    className="w-full px-3 py-2 border rounded-md"
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Country</label>
                <input
                  type="text"
                  placeholder="Country"
                  className="w-full px-3 py-2 border rounded-md"
                />
              </div>
              <p className="text-gray-500 text-sm mb-4">
                By providing your card information, you allow Zintellix, Inc. to
                charge your card for future payments in accordance with their
                terms.
              </p>
              <div className="flex justify-end">
                <button
                  type="button"
                  className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md mr-4"
                  onClick={toggleModal}
                >
                  Cancel
                </button>
                <button className="px-4 py-2 bg-blue-600 text-white rounded-md">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Billing;
