import React from "react";
import home_img from "../assets/images/onboarding_home.png";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/onboard-page");
  };

  return (
    <div className="">
      <div className="onboard h-[100vh] mx-auto sm:flex justify-center items-center">
        <img className="w-[25%]" src={home_img} alt="" />
        <div className="w-[35%] m-10">
          <div className="text-[#4D85D5] font-bold text-3xl my-6">
            Welcome to Zintellix
          </div>
          <div className="text-xl font-normal my-4">
            Unlock your team's potential with our intuitive SaaS.
          </div>
          <div className="text-base">
            We’re excited to have you onboard. Zintellix is designed to
            transform how your team accesses and leverages data to make smarter,
            faster business decisions.
          </div>
          <button
            className="onboard-button mt-5 w-[80%] h-[60px]"
            onClick={handleClick}
          >
            Let's go!
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
