import React, { useState } from "react";
import { reset } from "../constants/Reset";
import { toast } from "react-toastify";

const PasswordReset = () => {
  const [credentials, setCredentials] = useState({ email: "" });
  
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const response = await fetch("http://localhost:5000/api/auth/forget", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: credentials.email }),
    });
    const json = await response.json();
    // console.log("json", json, json.status);
    if (json.success) {
      toast.info("Check Your Email Inbox");
    } else {
      toast.error(json.message);
    }
  };

  const { email } = reset;

  return (
    <div className="bg-1 absolute w-[100vw] h-screen flex">
      <div className="bg-white justify-center w-1/3 my-auto sm:p-8 p-6 shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] rounded-lg overflow-hidden mx-auto">
        <div className="space-y-6">
          <div className="w-11/12">
            <img className="h-10 w-[150px]" src={email.logo} alt="Logo" />
          </div>
          <div className="my-5 w-11/12 px-4 items-left">
            <h3 className="text-xl grotesk font-medium">{email.title}</h3>
            <p className="onest text-[#52606D] ml-0 leading-8 font-normal">{email.content}</p>
          </div>
          <form className="w-11/12 items-center mx-auto" onSubmit={handleSubmit}>
            <div className="mx-1">
              <label className="text-sm mb-2 block">{email.label}</label>
              <div className="relative flex items-center">
                <input
                  name="email"
                  type="email"
                  value={credentials.email}
                  onChange={onChange}
                  required
                  className="bg-white border border-gray-300 w-full text-sm pl-4 pr-10 py-2.5 rounded outline-blue-500"
                  placeholder={email.placeholder}
                />
              </div>
            </div>
            <div className="mx-1 mt-9">
              <button type="submit" className="h-12 btn-design w-full">{email.button}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
