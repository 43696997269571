import React from "react";
import { Images } from "../constants/Images";
import { footer } from "../constants/Footer";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const handleClick = (route: string) => {
    navigate(route);
  };

  return (
    <div className="footer w-full bg-[#00264c] text-white min-h-[500px] flex flex-col justify-between">
      <div className="flex flex-col lg:flex-row justify-between px-4 sm:px-8 lg:px-20 py-5 lg:py-8">
        <div className="footer-l text-left mt-10 lg:mb-0 lg:w-1/3">
          <div className="f-logo flex items-center mb-4">
            <div className="img">
              <img
                className="footer-logo w-8 h-8 sm:w-10 sm:h-10"
                src={Images.footerLogo}
                alt=""
              />
            </div>
            <div className="ml-2">
              <div className="f-text text-xl sm:text-2xl font-bold">
                Zintellix
              </div>
            </div>
          </div>
          <div className="p-r text-sm sm:text-base max-w-xs sm:max-w-sm mb-4 mt-2">
            AI Data visualization, and expense management for your products.
          </div>
          <div className="f-logos flex justify-start space-x-4 mt-6">
            <a
              href="https://www.linkedin.com/company/zintellix/posts/?feedView=all"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="footer-logo w-6 h-6 sm:w-8 sm:h-8"
                src={Images.linkedin}
                alt="LinkedIn"
              />
            </a>
            <img
              className="footer-logo w-6 h-6 sm:w-8 sm:h-8"
              src={Images.insta}
              alt="Instagram"
            />
            <img
              className="footer-logo w-6 h-6 sm:w-8 sm:h-8"
              src={Images.twitter}
              alt="Twitter"
            />
          </div>
        </div>
        <div className="footer-r w-full lg:w-1/2 lg:pl-4">
          <div className="product grid grid-cols-2 sm:grid-cols-3 lg:gap-3">
            {footer.map((item) => (
              <ul key={item.id} className="p text-left mb-6 lg:mb-0">
                <li className="font-semibold mb-2 text-xl sm:text-2xl">
                  {item.text}
                </li>
                {item.subItems.map((subItem, index) => (
                  <li
                    key={index}
                    className="p-r cursor-pointer text-sm sm:text-base mb-1 hover:text-gray-600 mt-2"
                    onClick={() => handleClick(item.redirect[index])}
                  >
                    {subItem}
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>
      </div>
      <div className="px-4 sm:px-8 lg:px-20">
        <hr className="mb-4 w-full border-gray-600" />
        <div className="flex flex-col sm:flex-row py-4 items-center justify-between text-[#878C95] text-xs sm:text-sm">
          <div className="flex flex-wrap justify-center sm:justify-start space-x-2 sm:space-x-4 mb-4 sm:mb-0">
            <span
              className="cursor-pointer hover:text-gray-600"
              onClick={() => handleClick("/privacy")}
            >
              Privacy Policy
            </span>
            <span className="hidden sm:inline">|</span>
            <span
              className="cursor-pointer hover:text-gray-600"
              onClick={() => handleClick("/tnc")}
            >
              Terms & Conditions
            </span>
            <span className="hidden sm:inline">|</span>
            <span
              className="cursor-pointer hover:text-gray-600"
              onClick={() => handleClick("/cookie")}
            >
              Cookie Policy
            </span>
          </div>
          <div className="mb-4 sm:mb-0">
            <span>Made with ❤️ in Rajasthan</span>
          </div>
          <div>
            <span className="cursor-pointer hover:text-gray-600">
              © Zintellix 2024
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
