import React, { useEffect, useState } from "react";
import { TrustedBy } from "../constants/Carousel";
import parseStyles from "../utils/ParsingFunction";
import "../utils/style.css";

const Carousel = () => {
  const [contentCopies, setContentCopies] = useState(2);

  useEffect(() => {
    const updateContentCopies = () => {
      const screenWidth = window.innerWidth;
      const newCopies = Math.ceil(screenWidth / (200 * TrustedBy.length)) + 1;
      setContentCopies(newCopies);
    };

    updateContentCopies();
    window.addEventListener('resize', updateContentCopies);

    return () => window.removeEventListener('resize', updateContentCopies);
  }, []);

  return (
    <div className="carousel-container w-full overflow-hidden mt-8 md:mt-12 lg:mt-16">
      <div className="carousel-track">
        {Array(contentCopies).fill(TrustedBy).flat().map((data, key) => {
          const styles = parseStyles(data.style);
          return (
            <img
              className="carousel-item h-4 sm:h-5 md:h-6 lg:h-7 inline-block mx-4 sm:mx-6 md:mx-8"
              style={styles}
              src={data.img}
              alt={data.alt}
              key={key}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Carousel;