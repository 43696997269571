import React, { useState, useEffect } from "react";
import {
  userNotifications,
  adminNotifications,
} from "../../constant/notifications"; // Adjust the import path as necessary

interface NotificationPreferencesProps {
  role: string; // Ensure role is a string
}

const StyledCheckbox = ({
  checked,
  onChange,
}: {
  checked: boolean;
  onChange: () => void;
}) => (
  <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
    <input
      type="checkbox"
      name="toggle"
      id="toggle"
      className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
      checked={checked}
      onChange={onChange}
    />
    <label
      htmlFor="toggle"
      className={`toggle-label block overflow-hidden h-6 rounded-full cursor-pointer ${
        checked ? "bg-blue-500" : "bg-gray-300"
      }`}
    ></label>
  </div>
);

const NotificationPreferences: React.FC<NotificationPreferencesProps> = ({
  role,
}) => {
  // Select notification preferences based on the role
  const notificationPreferences =
    role === "admin" ? adminNotifications : userNotifications;

  // Initialize state dynamically based on selected notifications
  const [notifications, setNotifications] = useState<{
    [key: string]: boolean;
  }>({});

  // Update notifications state when role changes
  useEffect(() => {
    const initialNotificationsState = notificationPreferences.reduce(
      (acc, { key }) => ({ ...acc, [key]: true }),
      {}
    );
    setNotifications(initialNotificationsState);
  }, [notificationPreferences]); // Add role as a dependency

  const handleToggle = (key: string) => {
    setNotifications((prev) => ({
      ...prev,
      [key]: !prev[key as keyof typeof notifications],
    }));
  };

  const allNotificationsOn = Object.values(notifications).every((v) => v);

  return (
    <div className="max-w-6xl mx-auto">
      <h1 className="text-2xl font-bold mb-6">Notification Preferences</h1>

      <div className="bg-white p-6 rounded-lg shadow-md flex justify-between">
        <div className="flex flex-col space-y-2">
          <div>
            <div className="text-lg font-semibold">
              {role === "admin" ? "Admin Notifications" : "User Notifications"}
            </div>
            <p className="text-sm text-gray-500">
              Manage the notifications you want to receive.
            </p>
          </div>
          <StyledCheckbox
            checked={allNotificationsOn}
            onChange={() => {
              const updatedState = notificationPreferences.reduce(
                (acc, { key }) => ({ ...acc, [key]: !allNotificationsOn }),
                {}
              );
              setNotifications(updatedState);
            }}
          />
        </div>

        <div className="space-y-4 border p-5">
          {notificationPreferences.map(({ key, label, description }) => (
            <div key={key} className="flex items-start space-x-3">
              <input
                type="checkbox"
                checked={notifications[key as keyof typeof notifications]}
                onChange={() =>
                  handleToggle(key as keyof typeof notifications as string)
                }
                className="mt-1 h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <div>
                <p className="font-bold">{label}</p>
                <p className="text-sm text-gray-500">{description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NotificationPreferences;
