import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const TnC = () => {
  return (
    <div>
      <Header backgroundStyle=""/>
      <h1>Terms & Conditions</h1>
      <p>
        These Terms & Conditions ("Terms", "Terms & Conditions") govern your
        access to and use of the Zintellix website located at
        (https://www.zintellix.com/) and the related software as a service
        (SaaS) platform (collectively, the "Services").
        <b>
          By accessing or using the Services, you agree to be bound by these
          Terms.
        </b>
        If you disagree with any part of the Terms, then you may not access or
        use the Services.
      </p>
      <h1>1. Account Creation</h1>
      <p>
        <li className="list-disc">
          Youmustbeat least 18 years old to create an account and use the
          Services.
        </li>
        <li className="list-disc">
          Youareresponsible for maintaining the confidentiality of your account
          information and password. You are also fully responsible for all
          activities that occur under your account.
        </li>
        <li className="list-disc">
          Youagree to notify Zintellix immediately of any unauthorized use of
          your account or any other security breach.
        </li>
        <li className="list-disc">
          {" "}
          If any of our Services require you to create an account, you must
          complete the registration process by providing us with current,
          complete, and accurate information as prompted by the applicable
          registration form or you may login through a third-party processor
          such as your Google account. In the latter case, personal information
          you provided to that third party, such as your name, email address,
          and other information, your privacy settings on that service allow us
          to access, will be used to create your Zintellix account. Your member
          account will include a username and password of your choosing.{" "}
        </li>
        <li className="list-disc">
          Youareresponsible for maintaining the confidentiality of your password
          and account information. Furthermore, you are solely responsible for
          all activity that occurs under your account. If you notice any unusual
          activities or suspect unauthorized activity on your account or any
          other breach of security, you must immediately notify us at
          support@Zintellix.com. Zintellix will not be liable for any loss that
          you may incur as a result of someone else using your account or
          password, either with or without your knowledge. However, you could be
          held liable for losses incurred by Zintellix or our suppliers due to
          someone else using your account or password. You may not use anyone
          else’s account at any time, without the permission of the account
          holder.
        </li>
        <li className="list-disc">
          Wereserve the right to terminate unpaid user accounts and accounts
          that are inactive for more than 90 days. In the event of such
          termination, all data associated with such a user account will be
          deleted. We will provide you prior notice of such termination and
          option to back-up your data. This data deletion policy applies to all
          Services.
        </li>
      </p>
      <h1>2. Use of Services</h1>
      <p>
        <li className="list-disc">
          Youagree to use the Services only for lawful purposes and in
          accordance with these Terms.
        </li>
        <li className="list-disc">
          Youagree not to use the Services in any way that could damage,
          disable, overburden, or impair the Services or interfere with any
          other user's enjoyment of the Services.
        </li>
        <li className="list-disc">
          Youagree not to use the Services to obtain or disclose unauthorized
          information.
        </li>
        <li className="list-disc">
          Youagree not to upload, post, or transmit any content that is
          unlawful, harassing, defamatory, obscene, infringing, or otherwise
          objectionable.
        </li>
      </p>
      <h1>3.Software</h1>
      <p>
        Unless you have a valid agreement with Zintellix that entitles you to do
        so, you may not copy, reproduce, resell, sublicense, or redistribute any
        software made available to you. For your convenience, we may make
        available various tools and utilities for you to download and use as
        part of the Services.
        <br />
        Some of the tools and utilities provided as part of the Services or made
        available for download use artificial intelligence and machine learning
        (collectively, “AI”) to allow you, your employees and agents, or your
        customers and other end-users to submit text and other materials (a
        “Prompt”) and receive AI-generated results (“Output”) via various
        interfaces including APIs, chat bots, and web portals (collectively, the
        “GenAI Services”). Some of the GenAI Services may be integrated into
        your application or software environments through interfaces that allow
        users to submit Prompts and receive Outputs. We are constantly working
        to improve the AI features of our Services to make them more accurate,
        reliable, safe, and beneficial. Given the probabilistic nature of AI,
        use of the GenAI Services may result in incorrect Output that does not
        accurately reflect real people, places, or facts. The GenAI Services may
        generate responses by reading a user’s request and then predicting the
        next most likely words that might appear in response. In some cases, the
        most likely next words may not be the most factually accurate ones. For
        these reasons, you should not rely on the factual accuracy of Output
        from the GenAI Services. You should evaluate the accuracy of any Output
        as appropriate for your use case, including by using human review of the
        Output. Where human review is not possible or practical, you are
        responsible for providing appropriate notice to your users, including
        end users accessing AI through your platform, that is sufficient for
        them to understand the limitations of any AI features being used,
        including, but not limited to, that users should not rely on factual
        assertions in Outputs without independently fact checking their accuracy
        and that users may be exposed to content they may find offensive,
        inappropriate, or objectionable. You are solely responsible for the
        actions you take using the GenAI Services, including for ensuring that
        your use of the Output does not violate any applicable law or these
        Terms. You agree that you use the GenAI Services at your own risk.
      </p>
      <h1>4. Data</h1>
      <p>
        <li className="list-disc">
          Youareresponsible for the accuracy, completeness, and legality of all
          Data you provide to Zinsiehe.
        </li>
        <li className="list-disc">
          Yougrant Zintellix a non-exclusive, royalty-free, worldwide license to
          use, reproduce, modify, translate, distribute, and sublicense the Data
          for the purpose of providing the Services.
        </li>
        <li className="list-disc">
          Youretain all ownership rights to your Data
        </li>
      </p>
      <h1>5. Intellectual Property</h1>
      <p>
        <li className="list-disc">
          TheServices and all content included therein, such as text, graphics,
          logos, images, and software, are the property of Zintellix or its
          licensors and are protected by copyright, trademark, and other
          intellectual property laws.
        </li>
        <li className="list-disc">
          Youagree not to modify, reproduce, distribute, create derivative works
          of, or commercially exploit any content from the Services.
        </li>
      </p>
      <h1>6. Disclaimers</h1>
      <p>
        <li className="list-disc">
          THESERVICESAREPROVIDED"ASIS" AND"AS AVAILABLE" WITHOUT
          WARRANTYOFANYKIND, EXPRESS ORIMPLIED, INCLUDING BUT NOT LIMITED TO THE
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT.
        </li>
        <li className="list-disc">
          ZINTELLIX DOES NOTWARRANTTHATTHESERVICESWILLBE UNINTERRUPTED, SECURE,
          OR ERROR-FREE.
        </li>
        <li className="list-disc">
          ZINTELLIX DOES NOTWARRANTTHATTHERESULTSOBTAINED
          THROUGHTHEUSEOFTHESERVICESWILLBEACCURATEORRELIABLE.
        </li>
      </p>
      <h1>7. Limitations of Liability</h1>
      <p>
        ● ZINTELLIX WILL NOT BE LIABLE FOR ANY DAMAGES ARISING OUT OFOR RELATED
        TO YOURUSEOFTHESERVICES, INCLUDING BUT NOTLIMITED TO DIRECT, INDIRECT,
        INCIDENTAL, CONSEQUENTIAL, OR SPECIAL DAMAGES.
      </p>
      <h1>8. Term and Termination</h1>
      <p>
        <li className="list-disc">
          TheseTerms will remain in full force and effect while you use the
          Services.
        </li>
        <li className="list-disc">
          Wemaysuspendor terminate your access to the Services at any time, with
          or without cause, upon written notice to you.
        </li>
        <li className="list-disc">Youmayterminate your account at any time.</li>
      </p>
      <h1>9.Restrictions</h1>
      <p>
        The Services, including this Website, the Zintellix Platform and related
        technology, and the Zintellix software, documentation, APIs, training
        materials, and other content, is protected by the applicable national
        and international copyright, trademark, patent, trade secret, and other
        intellectual property laws and proprietary rights. Zintellix reserves
        all rights not expressly granted under these Terms, and no other rights
        are granted by implication, estoppel, or otherwise. You may only use the
        Platform and Services as permitted by law, including all applicable
        international, federal, state, and local laws and regulations. We
        reserve the right to investigate violations of these Terms and may also
        consult and cooperate with law enforcement authorities to prosecute
        users who violate the law.
        <br />
        You may not use the Platform or Services for any purpose that is
        unlawful or prohibited by these Terms. You may not use the Platform or
        Services in any manner that could damage, disable, overburden, or impair
        any Zintellix Platform or Services, or interfere with any other party’s
        use and enjoyment of the Platform or Services. You may not attempt to
        gain unauthorized access to the Platform or Services, or other accounts,
        computing systems, or networks and applications, through hacking,
        password mining, or any other means.
        <br />
        Do not, for example:
        <br />
        <li className="list-disc">
          Useanyengine, software, tool, agent, device, mechanism or the like to
          access, search, download, or use the Platform or Services in any way
          other than through our publicly supported interfaces;{" "}
        </li>
        <li className="list-disc">
          Access, tamper with, or use non-public areas of this Website, the
          Platform, or the technical delivery systems of our service providers;
        </li>
        <li className="list-disc">
          {" "}
          Probe, scan, or test the vulnerability of any Zintellix system or
          network or breach any security or authentication measures;
        </li>
        <li className="list-disc">
          {" "}
          Decipher, decompile, disassemble, or reverse engineer the Platform or
          any of the software used to provide this Website or the Services;{" "}
        </li>
        <li className="list-disc">
          {" "}
          Plant malware or use the Platform or Services to distribute malware; ●
          Violate the privacy of any third parties, including employees,
          consultants, service providers, of other users of the Services;{" "}
        </li>
        <li className="list-disc">
          {" "}
          Violate any applicable law or regulation;
        </li>
        <li className="list-disc">
          Impersonate or misrepresent your affiliation with any person or
          entity, or post or transmit anything that is fraudulent or misleading;
          or
        </li>
        <li className="list-disc">
          Attempt any of the above, or encourage or enable any third party to do
          any of the above.{" "}
        </li>
        <li className="list-disc">
          sendor store infringing, unsolicited, spam, offensive, obscene,
          threatening, harmful, libelous, abusive or which otherwise is of
          criminal or unethical nature according to applicable law.
        </li>
        <br />
        Some of the Services may contain bulletin boards, chat areas, user
        groups, forums, communities, personal webpages, calendars, photo albums,
        file cabinets, and email or other message or communication facilities
        designed to enable you to communicate with others (“Communication
        Services”). You agree to use the Communication Services only to post,
        send, and receive messages and material that are proper and, when
        applicable, related to the particular Communication Service. When using
        the Communication Services, you may not:
        <br />
        <li className="list-disc">
          {" "}
          UsetheCommunication Services in connection with surveys, contests,
          pyramid schemes, chain letters, junk email, spamming, or any
          duplicative or unsolicited messaging (commercial or otherwise);
        </li>
        <li className="list-disc">
          Sendunsolicited communications, promotions, advertisements, or spam;
        </li>
        <li className="list-disc">
          {" "}
          Defame, abuse, harass, stalk, threaten, or otherwise violate the legal
          rights (such as rights of privacy and publicity) of others;{" "}
        </li>
        <li className="list-disc">
          Publish, post, upload, distribute, or disseminate any offensive,
          inappropriate, profane, defamatory, obscene, indecent, or unlawful
          topic, material, or information;
        </li>
        <li className="list-disc">
          Upload, or otherwise make available, files that contain images,
          photographs, software, or other material protected by intellectual
          property laws, unless you own the rights thereto or have received all
          necessary consent to do the same;{" "}
        </li>
        <li className="list-disc">
          Useanymaterial or information, including images or photographs, which
          are made available through the Website or Communication Services in
          any manner that infringes any copyright, trademark, patent, trade
          secret, or other proprietary right of any party;
        </li>
        <li className="list-disc">
          Upload files that contain viruses, Trojan horses, worms, time bombs,
          cancel bots, corrupted files, or any other similar software or
          programs that may damage the operation of another’s property;{" "}
        </li>
        <li className="list-disc">
          {" "}
          Advertise or offer to sell or buy any goods or services for any
          business purpose, unless such Communication Services specifically
          allows such messages;
        </li>
        <li className="list-disc">
          Download any file posted by another user of the Communication Services
          that you know, or reasonably should know, cannot be legally
          reproduced, displayed, performed, or distributed;
        </li>
        <li className="list-disc">
          Falsify or delete any copyright management information, such as author
          attributions, legal or other notices, or proprietary designations or
          labels of the origin or source of software or other material contained
          in a file that is uploaded;{" "}
        </li>
        <li className="list-disc">
          Restrict or inhibit any other user from using and enjoying the
          Communication Services;{" "}
        </li>
        <li className="list-disc">
          Violate any code of conduct or other guidelines which may be
          applicable to the Communication Services;
        </li>
        <li className="list-disc">
          Harvest, mine, or otherwise collect information about others,
          including email addresses and any other personally identifiable
          information;
        </li>
        <li className="list-disc">
          Violate any applicable laws or regulations;
        </li>
        <li className="list-disc">
          Create a false identity for the purpose of misleading others; or
        </li>
        <li className="list-disc">
          Use,download, copy, or provide (whether or not for a fee) any
          directory of users of the Services or other user or usage information.
        </li>
        <br />
        Zintellix has no obligation to monitor the Communication Services.
        However, we reserve the right to review materials sent or posted using
        the Communication Services and to remove any materials at our sole
        discretion. Furthermore, we reserve the right to terminate your access
        to any or all of the Communication Services at any time, without notice,
        in case of violation of the above. We reserve the right at all times to
        disclose any information deemed necessary to satisfy any applicable law,
        regulation, legal process, or governmental request, or to edit, refuse
        to post, or to remove any information or materials, in whole or in part,
        at our sole discretion. Always use caution when providing any personally
        identifiable information in the Communication Services. Zintellix does
        not control or endorse the content, messages, or information in the
        Communication Services and specifically disclaims any liability with
        regard to the Communication Services and any actions resulting from your
        use of or participation in the Communication Services. Materials
        uploaded or posted to the Communication Services may be subject to
        limitations on usage, reproduction, or dissemination. You are
        responsible for adhering to any such limitations if you download any
        such materials.
      </p>
      <h1>10. Governing Law</h1>
      <p>
        ● TheseTerms will be governed by and construed in accordance with the
        laws of India, without regard to its conflict of law provisions.
      </p>
      <h1>11. Dispute Resolution</h1>
      <p>
        ● Anydispute arising out of or relating to these Terms will be resolved
        by arbitration in accordance with the Arbitration and Conciliation Act,
        1996. The arbitration will be conducted in Jaipur, Rajasthan, India.
      </p>
      <h1>12. Entire Agreement</h1>
      <p>
        ● TheseTerms constitute the entire agreement between you and Zintellix
        regarding your use of the Services.
      </p>
      <h1>13. Severability</h1>
      <p>
        ● If any provision of these Terms is held to be invalid or
        unenforceable, such provision shall be struck and the remaining
        provisions shall remain in full force and effect.
      </p>
      <h1>14. Waiver</h1>
      <p>
        ● Nowaiver of any provision of these Terms by Zintellix shall be deemed
        a further or continuing waiver of such provision or any other provision.
      </p>
      <h1>15. Contact Us</h1>
      <p>
        If you have any questions about these Terms, please contact us at
        Support@Zintellix.com.
      </p>
      <h1>This Terms & Conditions Policy is effective as of 28 May 2024</h1>
      <Footer />
    </div>
  );
};

export default TnC;
