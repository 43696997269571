// import React from "react";

// const Signup = () => {
//   return (
//     <div className="signup min-h-[35vh] flex justify-center items-center flex-col text-white mb-8 md:mb-12 px-4 sm:px-6 lg:px-8">
//       <div className="sign-text items-center text-center text-4xl w-[40%] mb-8">
//         Know the power of AI in your new Data Analysis platform.
//       </div>
//       <div className="">
//         <input type="email" placeholder="  Enter email" />
//         <button className="demo btn-design grotesk font-normal">Book a Demo</button>
//       </div>
//     </div>
//   );
// };

// export default Signup;

import React from "react";

const Signup = () => {
  return (
    <div className="signup min-h-[35vh] flex justify-center items-center flex-col text-white mb-8 md:mb-12 px-4 sm:px-6 lg:px-8">
      <div className="sign-text text-center items-center text-2xl sm:text-3xl md:text-4xl max-w-md sm:max-w-lg md:max-w-xl lg:max-w-2xl mb-6 sm:mb-8">
        Know the power of AI in your new Data Analysis platform.
      </div>
      <div className="flex flex-col sm:flex-row justify-center items-center w-full max-w-md">
        <input
          type="email"
          placeholder="Enter email"
          className="w-full sm:w-2/3 px-4 py-2 rounded-md text-black"
        />
        <button className="demo btn-design grotesk font-normal w-full sm:w-1/3 px-4 py-2 rounded-md">
          Book a Demo
        </button>
      </div>
    </div>
  );
};

export default Signup;
