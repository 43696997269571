// BlogDetail.tsx
import React from "react"; // Importing React
import { useParams } from "react-router-dom"; // Importing hooks from react-router-dom
import { blogDetail } from "../constants/BlogsData"; // Importing blog details from constants

const BlogDetail = () => {
    const { id } = useParams<{ id: string }>(); // Extracting blog ID from URL parameters
    const blogId = id ? parseInt(id, 10) : null; // Parsing the blog ID to an integer
  
    if (!blogId) { // Check if blogId is invalid
      return <div>Invalid blog ID</div>; // Render error message for invalid ID
    }
  
    const blog = blogDetail.find((blog) => blog.id === blogId); // Finding the blog by ID
  
    if (!blog) { // Check if blog is not found
      return <div>Blog not found</div>; // Render error message for blog not found
    }

    return (
      <div className="container mx-auto py-8"> {/* Main container for blog details */}
        <h1 className="text-3xl font-bold mb-4">{blog.title}</h1> {/* Blog title */}
        <img
          src={blog.imageUrl} // Blog image source
          alt={blog.title} // Alt text for the image
          className="w-full h-60 object-cover mb-4 rounded-lg" // Image styling
        />
        <p className="text-gray-700">{blog.content}</p> {/* Blog content */}
      </div>
    );
};

export default BlogDetail; // Exporting the BlogDetail component
