import React, { useState } from "react";

const WorkspaceSettings = () => {
  const [logo, setLogo] = useState<string | null>(null); // State to manage logo
  const [workspaceName, setWorkspaceName] = useState("Zintellix workspace"); // State for workspace name

  const handleLogoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogo(reader.result as string); // Update logo state with image data
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };

  const handleSave = () => {
    // Logic to save the workspace name (e.g., API call)
    console.log("Workspace name saved:", workspaceName);
    // You can replace the console.log with an API call to save the data
  };

  return (
    <div className=" max-w-6xl mx-auto">
      <h1 className="text-2xl font-bold mb-6">Workspace</h1>

      <div className="bg-white p-6 rounded-lg shadow-md space-y-6">
        <div className="flex justify-between">
          <h2 className="text-base font-large text-gray-700 mb-2">
            Workspace Logo
          </h2>
          <div className="flex items-center mr-20 space-x-4">
            <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center">
              {logo ? (
                <img
                  src={logo}
                  alt="Workspace Logo"
                  className="w-full h-full rounded-full"
                />
              ) : (
                <span className="text-2xl font-bold text-gray-500">Z</span>
              )}
            </div>
            <div className="flex flex-col items-start p-2">
              <label className="cursor-pointer mt-2 text-blue-600 hover:underline">
                Choose Logo
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleLogoChange}
                  className="hidden" // Hide the default file input
                />
              </label>
              <p className="text-xs text-gray-500 mt-1">
                (PNG, JPG or GIF, max 2MB)
              </p>
            </div>
          </div>
        </div>
        <hr />
        <div className="flex justify-between">
          <h2 className="text-base font-large text-gray-700 mb-2">
            Workspace Name
          </h2>
          <input
            type="text"
            value={workspaceName}
            onChange={(e) => setWorkspaceName(e.target.value)}
            className="w-1/3 p-2 border border-gray-300 rounded-md"
          />
        </div>
        <hr />
        <div className="flex justify-end space-x-4 mt-8">
          <button className="px-3 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50">
            Cancel
          </button>
          <button
            onClick={handleSave}
            className="px-3 py-2 bg-[#2E64E899] text-white rounded-md hover:bg-blue-700"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceSettings;
