import anaystImg1 from "../assets/images/analyst-img1.png";
import anaystImg2 from "../assets/images/analyst-img2.png";
import anaystImg3 from "../assets/images/analyst-img3.png";
import anaystImg4 from "../assets/images/analyst-img4.png";
import analystLogo11 from "../assets/images/AI Analyst/1.svg";
import analystLogo12 from "../assets/images/AI Analyst/2.svg";
import analystLogo13 from "../assets/images/AI Analyst/3.svg";
import analystLogo21 from "../assets/images/AI Analyst/4.svg";
import analystLogo22 from "../assets/images/AI Analyst/5.svg";
import analystLogo23 from "../assets/images/AI Analyst/6.svg";
import analystLogo31 from "../assets/images/AI Analyst/7.svg";
import analystLogo32 from "../assets/images/AI Analyst/8.svg";
import analystLogo33 from "../assets/images/AI Analyst/9.svg";
import analystLogo41 from "../assets/images/AI Analyst/10.svg";
import analystLogo42 from "../assets/images/AI Analyst/11.svg";
import analystLogo43 from "../assets/images/AI Analyst/12.svg";

// Feature AI Pricing
import pricingImg1 from "../assets/images/pricing-img1.png";
import pricingImg2 from "../assets/images/pricing-img2.png";
import pricingImg3 from "../assets/images/pricing-img3.png";
import pricingImg4 from "../assets/images/pricing-img4.png";
import pricingLogo11 from "../assets/images/AI Pricing/1.svg";
import pricingLogo12 from "../assets/images/AI Pricing/2.svg";
import pricingLogo13 from "../assets/images/AI Pricing/3.svg";
import pricingLogo21 from "../assets/images/AI Pricing/4.svg";
import pricingLogo22 from "../assets/images/AI Pricing/5.svg";
import pricingLogo23 from "../assets/images/AI Pricing/6.svg";
import pricingLogo31 from "../assets/images/AI Pricing/7.svg";
import pricingLogo32 from "../assets/images/AI Pricing/8.svg";
import pricingLogo33 from "../assets/images/AI Pricing/9.svg";
import pricingLogo41 from "../assets/images/AI Pricing/10.svg";
import pricingLogo42 from "../assets/images/AI Pricing/11.svg";
import pricingLogo43 from "../assets/images/AI Pricing/12.svg";

export const features = {
  FeatureAianalyst: [
    {
      id: 1,
      headings: [
        "AI That Understands Your Context",
        "Understands Your Business Vocabulary",
        "Fast & Accurate Responses",
      ],
      texts: [
        "Zin AI delivers insights finely tuned to your specific data, ensuring highly accurate results.",
        "Input your unique company vocabulary for AI to deliver precise, relevant responses to queries.",
        "The AI will process your questions and provide responses, including clarifications, query responses, and interpretations.",
      ],
      img: anaystImg1,
      alt: "AI Power",
      logos: [analystLogo11, analystLogo12, analystLogo13],
      featureType: "GIVES YOU THE POWER OF AI",
    },
    {
      id: 2,
      headings: [
        "Create Dashboards",
        "Dig Deeper With Questions",
        "Smart AI Reports",
      ],
      texts: [
        "Transform insights into visual representations by generating charts",
        "Ask follow-up questions in plain English to uncover rich insights.",
        "Get email reports with insights automatically derived by AI.",
      ],
      img: anaystImg2,
      alt: "Sales Analytics Image",
      logos: [analystLogo21, analystLogo22, analystLogo23],
      featureType: "COMPLETE ANALYTICS SOLUTIONS",
    },
    {
      id: 3,
      headings: ["Instant Data Decisions", "See Trends", "For Stakeholders"],
      texts: [
        "Get instant data answers without asking your data team, writing an SQL query",
        "Automatically spot anomalies, correlations and trends in your data",
        "Enable stakeholders to self-service answers their own business questions",
      ],
      img: anaystImg3,
      alt: "ROI image",
      logos: [analystLogo31, analystLogo32, analystLogo33],
      featureType: "DATA DRIVEN DECISIONS",
    },
    {
      id: 4,
      headings: [
        "Your Data Stays On Your Servers",
        "Reads Schema Only",
        "Control Data Access",
      ],
      texts: [
        "Relevant SQL queries run within your database ensuring data never leaves your servers.",
        "Only database schema is accessed without ever storing your data (privacy-first).",
        "Give team members access only to specific datasets using Role Based Access Controls.",
      ],
      img: anaystImg4,
      alt: "Countries Image",
      logos: [analystLogo41, analystLogo42, analystLogo43],
      featureType: "DERIVE INSIGHTS SECURELY",
    },
  ],
  FeatureAipricing: [
    {
      id: 1,
      headings: [
        "AI-Driven Automation",
        "Eliminates Manual Labour",
        "Real-Time Market Response",
      ],
      texts: [
        "It uses artificial intelligence to automate price adjustments, improving efficiency and freeing humans for more strategic work.",
        "Leverages automation to ditch manual work and set prices intelligently.",
        "Real-time market response in price optimization allows for automatic price adjustments based on up-to-the-second market conditions.",
      ],
      img: pricingImg1,
      logos: [pricingLogo11, pricingLogo12, pricingLogo13],
      featureType: "Automated Pricing Adjustment",
    },
    {
      id: 2,
      headings: [
        "Price Intelligence",
        "Price Optimization",
        "Product Matching",
      ],
      texts: [
        "Price Intelligence makes it simple for you to see historical pricing across your catalog and your competitors’ catalogs, no matter the size",
        "Optimize Prices with 99% Accuracy. It set prices that maximize profit and competitiveness.",
        "Pricing that matches similar products across the globe. It ensures prices are accurately compared across promotions for optimal allocation.",
      ],
      img: pricingImg2,
      logos: [pricingLogo21, pricingLogo22, pricingLogo23],
      featureType: "Pricing Recommendations",
    },
    {
      id: 3,
      headings: ["Pricing Strategies", "Price Management", "Pricing rules"],
      texts: [
        "Customize pricing as per your needs. It leverage data and automation to find the perfect price point that balances customer value and business profit.",
        "Deploy and Track price changes from one data-rich dashboard",
        "You are in full control of the rules you establish",
      ],
      img: pricingImg3,
      logos: [pricingLogo31, pricingLogo32, pricingLogo33],
      featureType: "Dynamic Pricing",
    },
    {
      id: 4,
      headings: [
        "Track Competitors",
        "Stay Competitive",
        "Improve your Margins",
      ],
      texts: [
        "Give promotions and discounts by studying competitors",
        "Our repricing engine integrates with marketplaces so you can automatically reprice hundreds to millions of SKUs.",
        "Increase your revenue and profits by 10%",
      ],
      img: pricingImg4,
      logos: [pricingLogo41, pricingLogo42, pricingLogo43],
      featureType: "Competitor Price Analysis",
    },
  ],
};
