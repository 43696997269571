import React, { useState } from "react";
import { Testinomial } from "../constants/Testinomial";
import { Images } from "../constants/Images";
import "../utils/style.css";

const Reviews = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);

  const handlePrevClick = () => {
    setIsFading(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? Testinomial.length - 1 : prevIndex - 1
      );
      setIsFading(false);
    }, 300);
  };

  const handleNextClick = () => {
    setIsFading(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === Testinomial.length - 1 ? 0 : prevIndex + 1
      );
      setIsFading(false);
    }, 300);
  };

  const data = Testinomial[currentIndex];

  return (
    <div className="right">
      <div className={`testimonial-card ${isFading ? "fade-out" : "fade-in"}`}>
        <div className="card flex relative onest bg-white text-2xl text-[#52606D] outline-blue-500">
          <div>
            <img src={Images.quotes} className="absolute left-5 top-5 w-4 h-4" alt="" />
          </div>
          <div className="text absolute top-10 justify-center">
            {data.text}
          </div>
        </div>
        <div className="flex mt-16 justify-between">
          <div className="flex">
            <div className="img">
              <img src={data.logo} alt="" className="person-img" />
            </div>
            <div className="details text-start ml-6 self-center">
              <div className="name">{data.name}</div>
              <div className="desig">{data.company}</div>
            </div>
          </div>
          <div className="arrow flex items-center mr-5">
            <img
              src={Images.left}
              alt="Previous"
              className="w-8 h-8 cursor-pointer bg-white m-2"
              onClick={handlePrevClick}
            />
            <img
              src={Images.right}
              alt="Next"
              className="w-8 h-8 cursor-pointer bg-white m-2"
              onClick={handleNextClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
