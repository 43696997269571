import { whyUsContent } from "../constants/WhyUs";
import { useNavigate } from "react-router-dom";

import React from 'react'

interface whyUsProps {
  WhyUs: whyUsContent[];
}

const WhyUsItem : React.FC<whyUsProps> = ({ WhyUs })=> {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/demo')
  }
  return (
    <div className="flex flex-col m-3 whyUs">
          <div className="why">
            <div>
              Why <span className="us">Us ?</span>
            </div>
          </div>
          {WhyUs.map((data, ind) => {
            const isEven = ind % 2 === 0;
            return (
              <div key={data.id}>
                {isEven ? (
                  <div className="banner why-left flex justify-between items-center text-left lefted">
                    <div className="whyleft relative flex flex-col top-0 items-baseline">
                      <div className="upper-down">
                        <img className="why-logo my-4" src={data.logo} alt="" />
                        <div className="why-heading grotesk">{data.heading}</div>
                      </div>
                      <div className="tab-content onest">{data.text}</div>
                      <button className="demo btn-design" onClick={handleClick}>Book A Demo</button>
                    </div>
                    <div className="right">
                      <img className="why-img h-[80%] w-[80%]" src={data.img} alt="" />
                    </div>
                  </div>
                ) : (
                  <div className="banner why-left flex justify-between items-center text-left righted">
                    <div className="right">
                      <img className="why-img h-[80%] w-[80%]" src={data.img} alt="" />
                    </div>
                    <div className="whyleft relative whyleft2 flex flex-col top-0  items-baseline">
                      <div className="upper-down">
                        <img className="why-logo my-4" src={data.logo} alt="" />
                        <div className="why-heading grotesk">{data.heading}</div>
                      </div>
                      <div className="tab-content onest">{data.text}</div>
                      <button className="demo btn-design" onClick = {handleClick}>Book A Demo</button>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
  )
}

export default WhyUsItem