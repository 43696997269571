import React from "react";
import "../utils/style.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
const PrivacyPolicy = () => {
  return (
    <div>
      <Header backgroundStyle=""/>
      <div className="ml-5 mt-2 mb-5 mr-5">
        <h1>Privacy Policy</h1>
        <p>
          Zintellix ("Zintellix", "we", "us", or "our") is committed to
          protecting the privacy of our users ("you" or "your"). This Privacy
          Policy explains how we collect, use, disclose, and protect your
          information when you use our website (https://www.zintellix.com/) and
          our related software as a service (SaaS) platform (collectively, the
          "Services").
        </p>
        <h1>Information We Collect</h1>
        <p>
          Wecollect several different types of information for various purposes
          to improve our Services to you.
        </p>
        <ul>
          <li>
            ● Personal Information: This includes information that can be used
            to identify you, such as your name, email address, phone number,
            company name, job title, and billing information. We collect this
            information when you register for an account, use the Services, or
            contact us for support.
          </li>
          <li>
            ● UsageData: This includes information about how you access and use
            the Services, such as the features you use, the pages you visit, and
            the time you spend on the platform. We collect this information
            automatically through cookies and other tracking technologies.
          </li>
          <li>
            ● DeviceInformation: This includes information about the device you
            use to access the Services, such as your device type, operating
            system, IP address, browser type, and unique device identifiers. We
            collect this information automatically to improve the Services and
            for security purposes.
          </li>
        </ul>
        <h1>Use of Information</h1>
        <p>Weuse the information we collect for various purposes, including:</p>
        <ul>
          <li>● Toprovide and improve the Services</li>
          <li>● Topersonalize your experience</li>
          <li>
            ● Tosendyoumarketing and promotional communications (with your
            consent)
          </li>
          <li>● Toanalyze how you use the Services</li>
          <li>● Totroubleshoot technical problems</li>
          <li>● Toprevent fraud and illegal activities</li>
        </ul>
        <h1>Disclosure of Information</h1>
        <p>
          Wemaydisclose your information to third-party vendors and service
          providers who help us operate the Services. These third parties are
          obligated to protect your information and use it only for the purposes
          we specify. Wemayalso disclose your information if we are required to
          do so by law or in the good faith belief that such disclosure is
          necessary to comply with a legal process, protect our rights, or
          protect the safety of others. Merger / Acquisition: As with any other
          company, we could merge with, or be acquired by another company,
          including in connection with a bankruptcy of Zintelix. If this occurs,
          the successor company would acquire the information we maintain,
          including Personal Data. However, Personal Data would remain subject
          to this Privacy Policy. Payments Processors: If and when we provide
          paid products and/or services on our Website, in such cases, we use
          third-party services for payment processing. We will not store or
          collect your payment card details. That information is provided
          directly to our third-party payment processors whose use of your
          personal information is governed by their Privacy Policy. While
          availing any such payment method(s) available, the website will not
          assume liability, whatsoever, arising out of the transactions or usage
          of data provided to such payment gateway partner.
        </p>
        <h1>Data Retention</h1>
        <p>
          Wewill retain your information for as long as your account is active
          or as needed to provide you with the Services. We may also retain your
          information for legal, accounting, or other business purposes.
        </p>
        <h1>Your Rights</h1>
        <p>You have certain rights regarding your information, including:</p>
        <ul>
          <li>● Theright to access your information</li>
          <li>● Theright to rectify inaccurate information</li>
          <li>● Theright to request the deletion of your information</li>
          <li>● Theright to object to the processing of your information</li>
          <li>● Theright to withdraw your consent (where applicable)</li>
        </ul>
        <h1>Security</h1>
        <p>
          Wetake reasonable steps to protect your information from unauthorized
          access, disclosure, alteration, or destruction. However, no internet
          transmission or electronic storage is 100% secure.
        </p>
        <h1>California Privacy Rights</h1>
        <p>
          If you are a resident of California, this section provides additional
          details about the personal information we collect about you, and your
          rights under the California Consumer Privacy Act, or “CCPA.” That we
          provide you access to details on the categories or specific pieces of
          personal information we collect and what we do with those data.
        </p>
        <h1>Your Privacy Rights</h1>
        <p>
          If you are an EU resident, under the GDPR, you have the following
          rights and we commit to provide you with the same:
        </p>
        <ul>
          <li>
            ● Right of Access: You have the right to get access to your Personal
            Data that is with us along with other supporting information.
          </li>
          <li>
            ● Right to Rectification: You have the right to ask us to rectify
            your Personal Data that is with us that you think is inaccurate. You
            also have the right to ask us to complete your Personal Data that
            you think is incomplete.
          </li>
          <li>
            ● Right to Erasure: You have the right to ask us to erase your
            Personal Data that is with us under certain circumstances.
          </li>
          <li>
            ● Right to Restriction of Processing: You have the right to ask us
            to restrict the processing of your Personal Data under certain
            circumstances.
          </li>
          <li>
            ● Right to Data Portability: You have the right to ask that we
            transfer the Personal Data you gave us to another organization, or
            you, under certain circumstances.
          </li>
          <li>
            ● Right to Object: You have the right to object to the processing of
            your Personal Data under certain circumstances.
          </li>
          <li>
            ● Right to not be subjected to Automated individual decision-making,
            including profiling
          </li>
          <li>
            ● Right to lodge a complaint with the Supervisory Authority: You
            have the right to lodge a complaint with a supervisory authority.
          </li>
        </ul>
        <h1>Children's Privacy</h1>
        <p>
          Our Services are not intended for children under the age of 18. We do
          not knowingly collect personal information from children.
        </p>
        <h1>Changes to this Privacy Policy</h1>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <h1>Contact Us</h1>
        <p>
          If you have any questions about this Privacy Policy, please contact us
          at support@zintellix.com. This Privacy Policy is effective as of 28
          May 2024
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
