import React from "react";
import "../utils/style.css";
import { Images } from "../constants/Images";

const IntegrationBanner = () => {
  return (
    <div className="int-banner relative w-full h-[500px] mb-5 overflow-hidden">
      <img
        src={Images.gAnalytics}
        alt=""
        className="absolute h-20 w-20 left-[15%] top-[15%]"
      />
      <img
        src={Images.amzRedshift}
        alt=""
        className="absolute h-20 w-20 left-[13%] top-[50%]"
      />
      <img
        src={Images.shopify}
        alt=""
        className="absolute h-20 w-20 left-[6%] top-[35%]"
      />
      <img
        src={Images.gAds}
        alt=""
        className="absolute h-20 w-20 left-[7%] top-[65%]"
      />
      <img
        src={Images.amplitude}
        alt=""
        className="absolute h-20 w-20 left-[14%] top-[80%]"
      />
      <img
        src={Images.postgress}
        alt=""
        className="absolute h-20 w-20 right-[15%] top-[15%]"
      />
      <img
        src={Images.hubspot}
        alt=""
        className="absolute h-20 w-20 right-[6%] top-[35%]"
      />
      <img
        src={Images.salesforce}
        alt=""
        className="absolute h-20 w-20 right-[13%] top-[50%]"
      />
      <img
        src={Images.gBigQuery}
        alt=""
        className="absolute h-20 w-20 right-[7%] top-[65%]"
      />
      <img
        src={Images.snowflake}
        alt=""
        className="absolute h-20 w-20 right-[14%] top-[80%]"
      />

      <div className="heading grotesk texting absolute w-2/5 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
        <h2 className="text-3xl sm:text-4xl md:text-5xl mb-2 sm:mb-3 md:mb-4">
          Integrations
        </h2>
        <p className="text-[#52606D] onest text-xl">
          Connect to your data stack. Get more insights by connecting Zintellix
          with popular tools.
        </p>
      </div>
    </div>
  );
};

export default IntegrationBanner;
