import React, { useState, useRef } from "react";
import { ROLE_OPTIONS, DEFAULT_PROFILE_IMAGE } from "../../constant/profile";

const Profile: React.FC = () => {
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [fullName, setFullName] = useState("");
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageClick = () => {
    fileInputRef.current?.click();
  };

  const handleDeleteImage = (e: React.MouseEvent) => {
    e.stopPropagation();
    setProfileImage(null);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Handle form submission logic here
    console.log("Form submitted:", { fullName, role, email, password });
  };

  const handleEmailUpdate = () => {
    // Implement email update logic here
    setEmail(newEmail);
    setIsEmailModalOpen(false);
    setNewEmail("");
  };

  const handlePasswordUpdate = () => {
    // Implement password update logic here
    setPassword(newPassword);
    setIsPasswordModalOpen(false);
    setCurrentPassword("");
    setNewPassword("");
  };

  // Common class names
  const inputClass =
    "w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500";
  const labelClass = "block mb-2 text-sm inter px-1 font-medium text-[#5A5D63]";
  const formGroupClass = "mb-4";

  return (
    <div className="max-w-lg p-4 bg-[#F5F5F580] opacity-1/2">
      <div className="text-2xl inter text-[#2D2E2E] font-semibold mb-6">
        Profile
      </div>
      <form onSubmit={handleSubmit}>
        <div className={formGroupClass}>
          <div
            className="w-32 h-32 my-5 rounded-full overflow-hidden relative cursor-pointer group"
            onClick={handleImageClick}
          >
            <img
              src={profileImage || DEFAULT_PROFILE_IMAGE}
              alt="Profile"
              className="w-full h-full object-cover bg-black bg-opacity-50"
            />
            <div className="absolute inset-0 flex items-center justify-center text-white text-sm font-semibold">
              {profileImage ? (
                <div className="flex-col transition-opacity duration-300 opacity-0 group-hover:opacity-100">
                  <span>Edit image</span>
                  <button
                    onClick={handleDeleteImage}
                    className="rounded-full p-1 text-xs"
                  >
                    Delete
                  </button>
                </div>
              ) : (
                <span>Add image</span>
              )}
            </div>
          </div>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="hidden"
            ref={fileInputRef}
          />
        </div>
        <div className={formGroupClass}>
          <label htmlFor="fullName" className={labelClass}>
            Full Name
          </label>
          <input
            type="text"
            id="fullName"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            className={inputClass}
            required
          />
        </div>
        <div className={formGroupClass}>
          <label htmlFor="role" className={labelClass}>
            Role
          </label>
          <select
            id="role"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className={inputClass}
            required
          >
            <option value="">Select a role</option>
            {ROLE_OPTIONS.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className={formGroupClass}>
          <label htmlFor="email" className={labelClass}>
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={inputClass}
            required
          />
          <div className="flex justify-end mt-2">
            <label
              onClick={() => setIsEmailModalOpen(true)}
              className="text-sm text-gray-500 cursor-pointer"
            >
              Change Email
            </label>
          </div>
        </div>
        <div className={formGroupClass}>
          <label htmlFor="password" className={labelClass}>
            Password
          </label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={inputClass}
            required
          />
          <div className="flex justify-end mt-2">
            <label
              onClick={() => setIsPasswordModalOpen(true)}
              className="text-sm text-gray-500 cursor-pointer"
            >
              Change Password
            </label>
          </div>
        </div>
        <button
          type="submit"
          className="w-1/3 bg-blue-400 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Update Info
        </button>
      </form>

      {/* Email Change Modal */}
      {isEmailModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-96">
            <h2 className="text-lg font-medium mb-4">Change Email</h2>
            <label htmlFor="Email" className={labelClass}>
              Current Email
            </label>
            <input
              type="email"
              placeholder="Current Email"
              className={`${inputClass} mb-4`}
              value={email}
              readOnly
            />
            <label htmlFor="email" className={labelClass}>
              New Email
            </label>
            <input
              type="email"
              placeholder="New Email"
              className={`${inputClass} mb-4`}
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
            />
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setIsEmailModalOpen(false)}
                className="px-4 py-2 w-1/3 text-sm text-gray-600 hover:bg-gray-200 bg-gray-100 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleEmailUpdate}
                className="w-1/3 bg-blue-400 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Password Change Modal */}
      {isPasswordModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-96">
            <h2 className="text-lg font-medium mb-4">Change Password</h2>
            <label htmlFor="password" className={labelClass}>
              Current Password
            </label>
            <input
              type="password"
              placeholder="Current Password"
              className={`${inputClass} mb-4`}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <label htmlFor="password" className={labelClass}>
              New Password
            </label>
            <input
              type="password"
              placeholder="New Password"
              className={`${inputClass} mb-4`}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <div className="flex justify-end space-x-2">
              <button
                onClick={() => setIsPasswordModalOpen(false)}
                className="px-4 py-2 w-1/3 text-sm text-gray-600 hover:bg-gray-200 bg-gray-100 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handlePasswordUpdate}
                className="w-1/3 bg-blue-400 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
