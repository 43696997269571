import uber from "../assets/images/carousel_logo/uber.png";
import samsung from "../assets/images/carousel_logo/samsung.png";
import puma from "../assets/images/carousel_logo/puma.png";
import volpac from "../assets/images/carousel_logo/volpak.png";
import lg from "../assets/images/carousel_logo/LG.png";
import hsbc from "../assets/images/carousel_logo/hsbc.png";


export const TrustedBy = [
    {
        "id": 1,
        "alt" : "hsbc",
        "img" : hsbc,
        "style" : `
        width : 160px`
    },
    {
        "id": 2,
        "alt" : "volpac",
        "img" : volpac,
        "style" : `
        width : 96px`
    },
    {
        "id": 3,
        "alt" : "puma",
        "img" : puma,
        "style" : `
        width : 36px`
    },
    {
        "id": 4,
        "alt" : "lg",
        "img" : lg,
        "style" : `
        width : 59px`
    },
    {
        "id": 5,
        "alt" : "uber",
        "img" : uber,
        "style" : `
        width : 80px`
    },
    {
        "id": 6,
        "alt" : "samsung",
        "img" : samsung,
        "style" : `
        width : 190px`
    }
];
