import React, { useState, useEffect } from "react";

interface Form3Props {
  formData: { role: string };
  handleRoleSelect: (role: string) => void;
  handleContinue: () => void;
}

const Form3: React.FC<Form3Props> = ({
  formData,
  handleRoleSelect,
  handleContinue,
}) => {
  const { role } = formData;
  // const [selectedRole, setSelectedRole] = useState<string | null>(null);

  const roles = [
    "Founder/CEO",
    "Product Manager",
    "Sales Manager",
    "Marketing Manager",
    "Operation Manager",
    "Financial Analyst",
    "HR Manager",
    "Category Manager",
  ];

  // const handleSubmit = () => {
  //   // Handle form submission logic here
  // };

  const [filled, setFilled] = useState(false);
  useEffect(() => {
    const { role } = formData;
    if (role) {
      setFilled(true);
    } else {
      setFilled(false);
    }
  }, [formData]);

  return (
    <div className="onboard flex h-screen justify-center items-center mx-auto drop-shadow-lg">
      <div className="bg-white shadow-lg rounded-2xl p-6 w-[35vw]">
        <div className="bg-neutral-400 w-11/12 mx-auto rounded-lg mb-5">
          <div className="progressbar bg-[#4D85D5] w-2/5 h-[5px] rounded-lg"></div>
        </div>
        <div className="my-5">
          <h2 className="text-center text-xl font-semibold text-black">
            To kick things off, which one describes your role best?
          </h2>
        </div>
        <div className="grid grid-cols-2 gap-5">
          {roles.map((r) => (
            <button
              key={r}
              onClick={() => handleRoleSelect(r)}
              className={`p-3 rounded-md border text-sm ${
                role === r
                  ? "bg-[#4D85D5] text-white"
                  : "bg-white text-black border-gray-300"
              }`}
            >
              {r}
            </button>
          ))}
        </div>
        <div className="flex items-center justify-between mt-10">
          <button
            onClick={handleContinue}
            type="button"
            className={`${
              filled ? "bg-[#4D85D5]" : "bg-[#4D85D580]"
            } w-[100%] h-[67px] rounded-xl text-lg text-white`}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default Form3;
