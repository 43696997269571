import React from "react";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Analytics from "./Analytics";

const Home = () => {
  return (
    <div className="flex flex-col h-screen">
      <Navbar />
      <div className="flex flex-1 overflow-hidden sticky">
        <Sidebar />
        <main className="flex-1 overflow-y-auto bg-gray-100 p-5">
          <Analytics />
        </main>
      </div>{" "}
      */
    </div>
  );
};

export default Home;
