import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../utils/style.css";
import { toast } from "react-toastify";

const Form:React.FC = () => {
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [formData, setFormData] = useState({
    name: "",
    companyName: "",
    email: "",
    phone: "",
    employees: "",
    message: "",
  });

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    if (name === 'email') {
      const forbiddenDomains = ['gmail.com', 'hotmail.com', 'yahoo.com', 'outlook.com'];
      const domain = value.split('@')[1];

      if (forbiddenDomains.includes(domain)) {
        setEmailError('Please use a business email address.');
      } else {
        setEmailError('');
      }
    }
    if (name === 'phone') {
      const phonePattern = /^[0-9\b]+$/;
      if (!value.match(phonePattern)) {
        setPhoneError('Please enter a valid phone number.');
      } else {
        setPhoneError('');
      }
      if (value.length > 15) {
        setPhoneError('Phone number cannot exceed 15 digits.');
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData);

    if (emailError || phoneError) {
      toast.error('Please correct the errors in the form.');
      return;
    }

    const response = await fetch("http://localhost:5000/api/book/demo", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });
    const json = await response.json();
    // console.log("json", json);
    if (json.success) {
      navigate("/aianalyst");
    } else {
      toast.error(json.error);
    }
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white shadow-md rounded-md form text-left font-normal">
      <form onSubmit={handleSubmit}>
        <div className="mb-5 mt-3 h-18">
          <label
            className="block text-gray-700 text-sm font-bold mb-2 font-normal"
            htmlFor="name"
          >
            Full Name
          </label>
          <input
            type="text"
            name="name"
            id="name"
            value={formData.name}
            onChange={handleChange}
            className="shadow appearance-none h-11 border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Your Name"
            required
          />
        </div>

        <div className="mb-5">
          <label
            className="block text-gray-700 text-sm font-bold mb-2 font-normal"
            htmlFor="companyName"
          >
            Company
          </label>
          <input
            type="text"
            name="companyName"
            id="companyName"
            value={formData.companyName}
            onChange={handleChange}
            className="shadow appearance-none h-11 border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Company Name"
            required
          />
        </div>

        <div className="mb-5">
          <label
            className="block text-gray-700 text-sm font-bold mb-2 font-normal"
            htmlFor="email"
          >
            E-mail (Business)
          </label>
          <input
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            className="shadow appearance-none h-11 border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Business Email"
            required
          />
          {emailError && <p className="text-red-500 text-xs italic">{emailError}</p>}
        </div>

        <div className="mb-5">
          <label
            className="block text-gray-700 text-sm font-bold mb-2 font-normal"
            htmlFor="phone"
          >
            Phone Number
          </label>
          <input
            type="tel"
            name="phone"
            id="phone"
            value={formData.phone}
            onChange={handleChange}
            className="shadow appearance-none h-11 border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Phone Number"
            required
          />
          {phoneError && <p className="text-red-500 text-xs italic">{phoneError}</p>}
        </div>

        <div className="mb-5">
          <label
            className="block text-gray-700 text-sm font-bold mb-2 font-normal"
            htmlFor="employees"
          >
            Company Size
          </label>
          <select
            name="employees"
            id="employees"
            value={formData.employees}
            onChange={handleChange}
            className="shadow appearance-none h-11 border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          >
            <option value="">Select...</option>
            <option value="<10">Less than 10</option>
            <option value="10-50">10-50</option>
            <option value="50-100">50-100</option>
            <option value=">100">More than 100</option>
          </select>
        </div>

        <div className="mb-5">
          <label
            className="block text-gray-700 text-sm font-bold mb-2 font-normal"
            htmlFor="message"
          >
            Message (Optional)
          </label>
          <textarea
            name="message"
            id="message"
            value={formData.message}
            onChange={handleChange}
            className="shadow appearance-none h-11 border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Your Message"
          />
        </div>

        <div className="flex items-center justify-between mb-5">
          <button type="submit" className="demo btn-design w-full h-4/5">
            Book a Demo
          </button>
        </div>
        <div className="flex items-center justify-between">
          <p className="block text-gray-700 text-sm font-bold mb-2 font-normal">
            For more information about how Zintellix handles your personal
            information, please visit our privacy policy.
          </p>
        </div>
      </form>
    </div>
  );
};

export default Form;
