import React, { useState, useEffect } from "react";

interface Form1Props {
  formData: {
    companyName: string;
    type: string;
    companySize: string;
  };
  handleFormChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  handleContinue: () => void;
}

const Form1: React.FC<Form1Props> = ({
  formData,
  handleFormChange,
  handleContinue,
}) => {
  const [filled, setFilled] = useState(false);
  useEffect(() => {
    const { companyName, type, companySize } = formData;
    if (companyName && type && companySize) {
      setFilled(true);
    } else {
      setFilled(false);
    }
  }, [formData]);
  return (
    <div className="onboard flex h-screen justify-center items-center mx-auto drop-shadow-lg">
      <form className="w-[35vw] bg-white p-8 rounded-2xl">
        <div className="progressbar bg-neutral-400 h-[5px] rounded-lg mb-5 mx-auto"></div>
        <div className="text-2xl text-center my-5">
          Tell us more about your company
        </div>
        <div className="mb-5">
          <label
            className="block text-gray-700 text-sm mb-2 font-normal"
            htmlFor="companyName"
          >
            Company
          </label>
          <input
            type="text"
            name="companyName"
            id="companyName"
            value={formData.companyName}
            onChange={handleFormChange}
            className="shadow appearance-none h-11 border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            placeholder="Company Name"
            required
          />
        </div>
        <div className="mb-5">
          <label
            className="block text-gray-700 text-sm mb-2 font-normal"
            htmlFor="companySize"
          >
            Company Size
          </label>
          <select
            name="companySize"
            id="companySize"
            value={formData.companySize}
            onChange={handleFormChange}
            className="shadow appearance-none h-11 border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          >
            <option value="">Select...</option>
            <option value="<10">Less than 50</option>
            <option value="50-100">51-100</option>
            <option value=">100">101-500</option>
            <option value=">100">More than 500</option>
          </select>
        </div>
        <div className="mb-5">
          <label
            className="block text-gray-700 text-sm mb-2 font-normal"
            htmlFor="type"
          >
            Industry
          </label>
          <select
            name="type"
            id="type"
            value={formData.type}
            onChange={handleFormChange}
            className="shadow appearance-none h-11 border rounded w-full py-2 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            required
          >
            <option value="">Select...</option>
            <option value="fashion">Apparel & Fashion</option>
            <option value="electronics">Electronics & Gadgets</option>
            <option value="beauty">Beauty & Personal Care</option>
            <option value="furniture">Furniture & Home Decor</option>
            <option value="health">Health & Wellness</option>
            <option value="food">Food & Beverage</option>
            <option value="tech">Technology & Gadgets</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div className="flex items-center justify-between mt-10">
          <button
            onClick={handleContinue}
            type="button"
            className={`${
              filled ? "bg-[#4D85D5]" : "bg-[#4D85D580]"
            } w-[100%] h-[67px] rounded-xl text-lg text-white`}
          >
            Continue
          </button>
        </div>
      </form>
    </div>
  );
};

export default Form1;
