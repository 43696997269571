import React, { useState, useEffect } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useUser } from "../UserContext";
import { Images } from "../constants/Images";
import { toast } from "react-toastify";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { setUser, user } = useUser();
  const [credentials, setCredentials] = useState({ email: "", password: "" });

  // Function to handle form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const response = await fetch("http://localhost:5000/api/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: credentials.email,
        password: credentials.password,
      }),
    });

    const json = await response.json();
    if (json.success) {
      localStorage.setItem("authToken", json.authToken);
      localStorage.setItem("Authorization", json.authToken);
      setUser(json.user);
      toast.success("Login Successful");
      navigate("/aianalyst");
    } else {
      toast.error("Invalid credentials");
    }
  };

  // Function to handle input changes
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  // Effect to log user state changes
  useEffect(() => {
    console.log("Updated user state:", user);
  }, [user]);

  // Google login configuration
  const login = useGoogleLogin({
    onSuccess: async (response: { access_token: string }) => {
      // Ensure the parameter type is correct
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${response.access_token}`,
            },
          }
        );
        const resp = await axios.post("http://localhost:5000/api/auth/users", {
          email: res.data.email,
          name: res.data.name,
          authSource: "google",
          profilePicture: res.data.picture,
        });

        setUser(resp.data);
        navigate("/aianalyst", { state: { val: res.data } });
      } catch (err) {
        toast.error("Google login failed");
      }
    },
  });

  // Function to navigate to registration page
  const handleClick = () => navigate("/register");

  // Function to navigate to reset password page
  const resethandleClick = () => navigate("/reset");

  return (
    <div className="bg-1 absolute w-[100vw] h-screen">
      <div className="bg-white grid w-1/3 mt-9 sm:p-8 p-6 shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] rounded-xl overflow-hidden mx-auto">
        <div className="w-11/12">
          <img
            className="h-10 w-[150px]"
            src={Images.zintellixLogo}
            alt="Zintellix Logo"
          />
        </div>
        <div className="my-5 w-11/12 px-4 items-left">
          <h3 className="text-xl grotesk font-medium">Log In</h3>
          <p className="onest text-[#52606D] ml-0 leading-8 font-normal">
            to continue to Zintellix
          </p>
        </div>
        <div className="px-4 py-2 my-1 flex items-center justify-center">
          <div
            onClick={() => login()} // Call login without passing the event
            className="w-full px-4 py-2 my-1 text-[#52606D] flex items-center justify-center border-2 border-slate-700 rounded-full cursor-pointer"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/1024px-Google_%22G%22_logo.svg.png"
              className="w-6 h-6 mr-5"
              alt="Google Logo"
            />
            Continue with Google
          </div>
        </div>

        <div className="flex items-center my-4">
          <hr className="w-1/2 text-black" />
          <p className="text-center">OR</p>
          <hr className="w-1/2 mr-auto" />
        </div>

        <form className="w-11/12 items-center mx-auto" onSubmit={handleSubmit}>
          <div className="space-y-6">
            <div>
              <label className="text-sm mb-2 block">Email Id</label>
              <input
                name="email"
                type="email"
                value={credentials.email}
                onChange={onChange}
                required
                className="bg-white border border-gray-300 w-full text-sm pl-4 pr-10 py-2.5 rounded outline-blue-500"
                placeholder="Enter email"
              />
            </div>
            <div>
              <label className="text-sm mb-2 block">Password</label>
              <input
                name="password"
                type="password"
                value={credentials.password}
                onChange={onChange}
                required
                className="bg-white border border-gray-300 w-full text-sm pl-4 pr-10 py-2.5 rounded outline-blue-500"
                placeholder="Enter password"
              />
            </div>
            <div className="forgot">
              <button
                onClick={resethandleClick}
                type="button"
                className="text-blue-600 font-semibold hover:underline ml-1 cursor-pointer bg-transparent border-none"
              >
                Forgot password?
              </button>
            </div>
            <div className="!mt-9">
              <button type="submit" className="btn-design w-full h-12">
                Continue
              </button>
            </div>
          </div>
        </form>

        <div className="text-center mt-4">
          <button
            onClick={handleClick}
            className="text-blue-600 font-semibold hover:underline cursor-pointer bg-transparent border-none"
          >
            Create an account
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
