import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../UserContext";
import { Navbar } from "../constants/Navbar";
import { Images } from "../constants/Images";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import parseStyles from "../utils/ParsingFunction";

interface NavbarProps {
    backgroundStyle : string;
}
const Header: React.FC<NavbarProps> = (props) => {
  const {backgroundStyle} = props;
  const backgroundStyles = backgroundStyle? parseStyles(backgroundStyle) : {};
  const { user } = useUser();
  const navigate = useNavigate();
  const [hoveredItem, setHoveredItem] = useState<number | null>(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState<number | null>(null);

  const handleClick = () => {
    navigate("/demo");
  };

  const handleMouseEnter = (id: number) => {
    setHoveredItem(id);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const handleDropdownClick = (redirectUrl: string) => {
    navigate(redirectUrl);
  };

  const handleSignInClick = () => {
    navigate("/login");
    // navigate("/onboard");
  };

  const handleItemClick = (redirectUrl?: string | string[]) => {
    if (typeof redirectUrl === 'string') {
      navigate(redirectUrl);
    }
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
    setOpenDropdown(null); // Close any open dropdown when toggling the menu
  };

  const toggleDropdown = (index: number) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  return (
    <div className="nav navbar flex flex-row justify-between items-center text-[#52606D] p-4" style={backgroundStyles}>
      <div className="logo">
        <Link to="/">
          <img className="logo-img h-8 md:h-10" src={Images.zintellixLogo} alt="Logo" />
        </Link>
      </div>
      
      {/* Desktop Menu */}
      <ul className="hidden md:flex justify-center space-x-4">
        {Navbar.map((data) => (
          <li
            key={data.id}
            className="relative flex flex-col items-center group"
            onMouseEnter={() => handleMouseEnter(data.id)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleItemClick(data.redirect)}
          >
            <div className="cursor-pointer flex items-center mb-2">
              {data.text}
              {/* if logo is present then on hoveing it will do the opposite direction of the icon (down icon and up icon) */}
              {data.logo &&
                (hoveredItem === data.id ? (
                  <KeyboardArrowUpIcon className="ml-1" />
                ) : (
                  <KeyboardArrowDownIcon className="ml-1" />
                ))}
            </div>
            {/* if dropdown items are present then only code will enter in this block */}
            {data.dropdownItems && (
              <div className="dropdown-menu absolute left-0 mt-9 bg-white rounded-lg border border-gray-200 shadow-lg z-10 hidden group-hover:block">
                <div className="m-4 ml-5 mb-2 text-[#5B5B55]">{data.text}</div>
                <ul>
                  {data.dropdownItems.map((item, index) => (
                    <li
                      key={index}
                      className="mt-2 px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent parent onClick from firing
                        handleDropdownClick(data.redirect[index]);
                      }}
                    >
                      <img
                        src={data.drop[index]}
                        alt={item}
                        className="mr-3 h-9 w-9"
                      />
                      <div className="flex flex-col">
                        <span>{item}</span>
                        <span className={`${data.style[index]} font-normal text-[#7C7C73]`}>
                          {data.subtitle[index]}
                        </span>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </li>
        ))}
      </ul>
      
      {/* Mobile Menu Button */}
      <button className="md:hidden" onClick={toggleMobileMenu}>
        {mobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
      </button>
      
      {/* Mobile Menu */}
      {mobileMenuOpen && (
        <div className="absolute top-16 left-0 right-0 bg-white shadow-lg z-20 md:hidden">
          <ul className="flex flex-col">
            {Navbar.map((data, index) => (
              <li key={data.id} className="border-b">
                <div 
                  className="flex justify-between items-center p-4"
                  onClick={() => data.dropdownItems ? toggleDropdown(index) : handleItemClick(data.redirect)}
                >
                  {data.text}
                  {data.dropdownItems && (
                    openDropdown === index ? <KeyboardArrowUpIcon className="ml-1" /> : <KeyboardArrowDownIcon className="ml-1" />
                  )}
                </div>
                {data.dropdownItems && openDropdown === index && (
                  <ul className="bg-gray-100">
                    {data.dropdownItems.map((item, subIndex) => (
                      <li
                        key={subIndex}
                        className="py-2 px-8"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDropdownClick(data.redirect[subIndex]);
                          setMobileMenuOpen(false);
                        }}
                      >
                        {item}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
      
      {/* Login/Demo Buttons */}
      <div className="hidden md:flex items-center space-x-4">
        <button className="demo btn-design text-sm" onClick={handleClick}>
          Book A Demo
        </button>
        {/* if user is present then take its profile image from google account otherwise show a deefault icon image */}
        {user ? (
          <img
            src={user.picture || Images.defaultProfile}
            alt="Profile"
            className="h-8 w-8 rounded-full cursor-pointer"
            onClick={handleSignInClick}
          />
        ) : (
          <p className="cursor-pointer text-sm" onClick={handleSignInClick}>
            Sign in
          </p>
        )}
      </div>
    </div>
  );
};

export default Header;
