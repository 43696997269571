import analyticslogo from "../../assets/images/workspace_logos/sidebar/analytics.png";
import settingslogo from "../../assets/images/workspace_logos/sidebar/Settings.png";
import zinAilogo from "../../assets/images/workspace_logos/sidebar/zinAi.png";
import dashboardsLogo from "../../assets/images/workspace_logos/sidebar/dashboards.png";
import dataSourcesLogo from "../../assets/images/workspace_logos/sidebar/datasources.png";
import basic from "../../assets/images/workspace_logos/zin_plans/basic.png";
import pro from "../../assets/images/workspace_logos/zin_plans/pro.png";
import enterprise from "../../assets/images/workspace_logos/zin_plans/enterprise.png";
import profile from "../../assets/images/workspace_logos/settings_logo/profile.png";
import plans from "../../assets/images/workspace_logos/settings_logo/plans.png";
import about from "../../assets/images/workspace_logos/settings_logo/about.png";
import teams from "../../assets/images/workspace_logos/settings_logo/teams.png";
import docs from "../../assets/images/workspace_logos/drop_icon/docs.png";
import workspace from "../../assets/images//workspace_logos/settings_logo/workspace.png";
import notification from "../../assets/images/workspace_logos/settings_logo/notification.png";
import zintellixLogo from "../../assets/images/zintellixLogo.svg";
import left from "../../assets/images/left-arrow.png";

export const Images = {
  zintellixLogo,
  analyticslogo,
  settingslogo,
  zinAilogo,
  dashboardsLogo,
  dataSourcesLogo,
  basic,
  pro,
  enterprise,
  profile,
  plans,
  about,
  notification,
  workspace,
  teams,
  docs,
  left,
};
