export const footer = [
  {
    id: 1,
    text: "Product",
    subItems: ["AI Analyst", "AI Pricing"],
    redirect: ["/aianalyst", "/aiprice"],
    style: ["", "", ""],
  },
  {
    id: 2,
    text: "Resources",
    subItems: ["Blog", "Developer Docs", "Support"],
    redirect: ["/blogs", "/", "/"],
    style: ["", "", ""],
  },
  {
    id: 3,
    text: "Company",
    subItems: ["About Us", "Use Cases", "Integrations", "Contact Us"],
    redirect: ["/about", "/usage", "/integrations", "/demo"],
    style: ["", "", ""],
  },
];
