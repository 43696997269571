import React from "react";
import { Images } from "../constants/Images";
const Integration = () => {
  return (
    <div className="integration w-full mb-10 flex justify-center h-[652px]">
      <img
        className="integration-img w-4/5 h-4/5 my-auto mx-auto"
        src={Images.bigintegration}
        alt=""
      />
    </div>
  );
};

export default Integration;
