import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { reset } from "../constants/Reset";
import { toast } from "react-toastify";

const NewPassword: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [credentials, setCredentials] = useState({ password: "" });

  // Extract token and id from query parameters
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const userId = queryParams.get("id");

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!userId || !token) {
      toast.error("Invalid request. Missing userId or token.");
      return;
    }

    const response = await fetch("http://localhost:5000/api/auth/reset-pass", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ password: credentials.password, userId, token }),
    });
    const json = await response.json();
    if (json.success) {
      toast.info(json.message);
      navigate("/login");
    } else {
      toast.error("Invalid credentials");
    }
  };

  const { password } = reset;

  return (
    <div className="bg-1 absolute w-[100vw] h-screen flex">
      <div className="bg-white justify-center w-1/3 my-auto sm:p-8 p-6 shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] rounded-xl overflow-hidden mx-auto">
        <div className="space-y-6">
          <div className="w-11/12">
            <img className="h-10 w-[150px]" src={password.logo} alt="Logo" />
          </div>
          <div className="my-5 w-11/12 px-4 items-left">
            <h3 className="text-xl grotesk font-medium">{password.title}</h3>
          </div>
          <form
            className="w-11/12 items-center mx-auto"
            onSubmit={handleSubmit}
          >
            <div className="mx-1">
              <label className="text-sm mb-2 block">{password.label}</label>
              <div className="relative flex items-center">
                <input
                  name="password"
                  type="password"
                  value={credentials.password}
                  onChange={onChange}
                  required
                  className="bg-white border border-gray-300 w-full text-sm pl-4 pr-10 py-2.5 rounded outline-blue-500"
                  placeholder={password.placeholder}
                />
              </div>
            </div>
            {/* <PasswordChecklist
              rules={["minLength", "specialChar", "number", "capital"]}
              minLength={5}
              value={credentials.password}
              onChange={(isValid) => {}}
            /> */}
            <div className="mx-1 mt-9">
              <button type="submit" className="h-12 btn-design w-full">
                {password.button}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;

