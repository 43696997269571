import powerbi from "../assets/images/powerbi.png";
import dashboard from "../assets/images/dashboard.png"
import zintellixLogo from "../assets/images/zintellixLogo.svg"
import insta from "../assets/images/insta.png";
import twitter from "../assets/images/twitter.png";
import linkedin from "../assets/images/linkedin.png";
import footerLogo from "../assets/images/footer-logo.png"
import left from "../assets/images/left-arrow.png";
import right from "../assets/images/right-arrow.png";
import Integration from "../assets/images/integration.png";
import plus from "../assets/images/plus.png"
import bigintegration from "../assets/images/bigintegration.png"
import analystBanner from "../assets/images/analyst-banner.png"
import pricingBanner from "../assets/images/pricing-banner.png";
import defaultProfile from "../assets/images/footer-logo.png";
import salesforce from "../assets/images/integration_banner_logos/salesforce.png";
import shopify from "../assets/images/integration_banner_logos/shopify.png"
import amplitude from "../assets/images/integration_banner_logos/amplitude.png"
import hubspot from "../assets/images/integration_banner_logos/hubspot.png"
import postgress from "../assets/images/integration_banner_logos/postgress.png"
import amzRedshift from "../assets/images/integration_banner_logos/amzRedshift.png"
import gAds from "../assets/images/integration_banner_logos/gAds.png"
import snowflake from "../assets/images/integration_banner_logos/snowflake.png"
import gBigQuery from "../assets/images/integration_banner_logos/gBigQuery.png"
import gAnalytics from "../assets/images/integration_banner_logos/gAnalytics.png"
import quotes from "../assets/images/quotes.svg";
import blog1 from "../assets/images/blog1.png";
import blog2 from "../assets/images/blog2.png";
import blog3 from "../assets/images/blog3.png";
import blog4 from "../assets/images/blog4.png";

export const Images = {
    dashboard,
    powerbi,
    zintellixLogo,
    insta, twitter,linkedin,
    footerLogo,left,right,
    Integration,plus, bigintegration, quotes,
    pricingBanner,analystBanner, defaultProfile, 
    salesforce, shopify, amplitude, hubspot, amzRedshift, gAds, gBigQuery, snowflake, gAnalytics, postgress,
    blog1, blog2, blog3,blog4
};