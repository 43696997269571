import React, { useState } from "react";
import { Images } from "../constant/WorkspaceImages"; // Import your image paths here
import { NavLink, useLocation } from "react-router-dom";
import { settingsLinks, nonSettingsLinks } from "../constant/sidebar";

const Sidebar: React.FC = () => {
  const [sidebarWidth, setSidebarWidth] = useState(250);
  const [isDragging, setIsDragging] = useState(false);
  const location = useLocation();

  const startResize = (e: React.MouseEvent) => {
    setIsDragging(true);
    document.addEventListener("mousemove", resize);
    document.addEventListener("mouseup", stopResize);
  };

  const resize = (e: MouseEvent) => {
    if (isDragging) {
      const newWidth = e.clientX;
      if (newWidth > 100 && newWidth < 400) {
        setSidebarWidth(newWidth);
      }
    }
  };

  const stopResize = () => {
    setIsDragging(false);
    document.removeEventListener("mousemove", resize);
    document.removeEventListener("mouseup", stopResize);
  };

  const isSettingsActive = location.pathname.startsWith("/workspace/settings");

  const navLinkClass = ({ isActive }: { isActive: boolean }) =>
    `block py-2 px-4 text-gray-700 inter transition-colors duration-200 flex items-center ${
      isActive
        ? "bg-blue-100 text-blue-500 inter font-semibold"
        : "hover:bg-blue-50 hover:text-blue-600 inter"
    }`;

  const renderLinks = (
    links: { path: string; label: string; icon: keyof typeof Images }[]
  ) =>
    links.map((link) => (
      <NavLink to={link.path} className={navLinkClass} key={link.path}>
        <img
          src={Images[link.icon]}
          className="h-4 w-4 mr-2"
          alt={link.label}
        />
        {link.label}
      </NavLink>
    ));

  return (
    <div
      className="bg-white shadow-md flex flex-col top-17 sticky"
      style={{
        width: `${sidebarWidth}px`,
        minWidth: "100px",
        maxWidth: "250px",
      }}
    >
      <nav className="mt-4 flex-grow">
        {isSettingsActive
          ? renderLinks(settingsLinks)
          : renderLinks(nonSettingsLinks)}

        <button
          className="bg-[#F5F5F5] mx-10 text-black px-3 text-sm border border-black py-2 rounded-md mt-4 top-[88%] fixed"
          onClick={() => (window.location.href = "/upgrade-plan")}
        >
          Upgrade Now
        </button>
      </nav>
      <div
        className="w-1 bg-gray-300 absolute top-0 right-0 h-full cursor-ew-resize"
        onMouseDown={startResize}
      ></div>
    </div>
  );
};

export default Sidebar;
