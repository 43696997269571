import Header from "../components/Header";
import BannerItem from "../components/BannerItem";
import Signup from "../components/Signup";
import Integration from "../components/Integration";
import Footer from "../components/Footer";
import { Images } from "../constants/Images";
import { Banner } from "../constants/Banner";
import AiPricingItem from "../components/AiPricingItem";

const AiPricingPage = () => {
  return (
    <div>
      <Header backgroundStyle=""/>
      <BannerItem
        content={Banner.aipricing.content}
        button = {Banner.aipricing.button}
        page=""
        image={Images.pricingBanner}
        backgroundStyle="height: 80vh; background: linear-gradient(96.3deg, #FEF3ED 0%, #E8F4FF 100.39%);"
        imageStyle="width:645px; height:495px; position:absolute; right:5rem"
      />
      <AiPricingItem />
      <Signup />
      <Integration />
      <Footer />
    </div>
  );
};

export default AiPricingPage;
