import React, { useState } from "react";
import { categories, companies } from "../../constants/Integrations";

const DataConnector: React.FC = () => {
  const [connectedCompanies, setConnectedCompanies] = useState<number[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleConnectClick = (companyId: number) => {
    setConnectedCompanies((prevConnectedCompanies) =>
      prevConnectedCompanies.includes(companyId)
        ? prevConnectedCompanies.filter((id) => id !== companyId)
        : [...prevConnectedCompanies, companyId]
    );
  };

  const filteredCompanies = companies.filter((company) =>
    company.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="integrations w-full">
      <h1 className="text-3xl mb-6">Data Sources</h1>

      {/* Search Bar */}
      <div className="mb-8 flex">
        <input
          type="text"
          placeholder="Search companies..."
          className="w-[30%] p-4 border border-gray-300 rounded-xl"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {categories.map((category) => {
        const categoryCompanies = filteredCompanies.filter((company) =>
          category.companyIds.includes(company.id)
        );

        if (categoryCompanies.length === 0) {
          return null;
        }

        return (
          <div key={category.id} className="mb-10">
            <h2 className="text-2xl text-[#2D2E2E] inter mb-4">
              {category.name}
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {categoryCompanies.map((company) => (
                <div
                  key={company.id}
                  className="flex items-center p-4 bg-white shadow rounded-lg"
                >
                  <img
                    src={company.imageUrl}
                    alt={company.name}
                    className="w-12 h-12 object-contain mr-6"
                  />
                  <div className="flex items-center justify-between w-full">
                    <h3 className="text-lg text-[#2D2E2E] inter">
                      {company.name}
                    </h3>
                    <button
                      className={`border text-sm rounded-full px-3 py-1 ${
                        connectedCompanies.includes(company.id)
                          ? "bg-[#43C77333] text-[#43C773]"
                          : "bg-none text-blue-500 border-black-100"
                      }`}
                      onClick={() => handleConnectClick(company.id)}
                    >
                      {connectedCompanies.includes(company.id)
                        ? "Connected"
                        : "Connect"}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default DataConnector;
