import dataLogo from "../assets/images/DataLogo.png";
import pricing from "../assets/images/pricing.png";
import integrationlogo from "../assets/images/integration.png";
import automated from "../assets/images/automated-pricing.png";
import BiAuto from "../assets/images/BIautomation.png";
import Dstory from "../assets/images/dataStory.png";
import nocode from "../assets/images/NoCode.png";
import integration from "../assets/images/integrationFeature.png";

export const Feature = [
  {
    "id": 1,
    "heading": "No Code Data Analysis",
    "text": "The ability to analyze and interpret the collected data using machine learning algorithms to identify patterns and trends.",
    "img": nocode,
    "logo": dataLogo,
    "style": 'width : 80%; height: 90%'
  },
  {
    "id": 2,
    "heading": "Data Storytelling",
    "text": "Build and customize dashboards with the help of AI to share insights within your organization.",
    "img": Dstory,
    "logo": pricing,
    "style": 'width : 80%; height: 90%'
  },
  {
    "id": 3,
    "heading": "BI Automation",
    "text": "Save team time by automating BI reporting. Spot anomalies, correlations, and trends in your data",
    "img": BiAuto,
    "logo": automated,
    "style": 'width : 95%; height: 85%'
  },
  {
    "id": 4,
    "heading": "Integration",
    "text": "Import data from product analytics, databases, data warehouses, advertising platforms and other tools you use every day, all without code.(Postgres, MySQL, Snowflake, BigQuery, and more).",
    "img": integration,
    "logo": integrationlogo,
    "style": 'width : 95%; height: 85%'
  }
];
