import React, { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { ArrowUpRight, ArrowDownRight } from "lucide-react";
import {
  metricCardsData,
  salesFunnelData,
  newVsRepeatData,
  // productsData,
  MetricData,
  marketingData,
  productSalesData,
  marketingTableHeaders,
  productTableHeaders,
} from "../constant/analytics";

const totalSum = newVsRepeatData.reduce((sum, entry) => sum + entry.value, 0);

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const { name, value } = payload[0];
    const percentage = ((value / totalSum) * 100).toFixed(2);

    return (
      <div
        className="custom-tooltip"
        style={{ background: "#fff", padding: "1px", border: "1px solid #ccc" }}
      >
        <p className="label">{`${name}: ${percentage}%`}</p>
      </div>
    );
  }

  return null;
};

// Metric Card Component (updated)
const MetricCard: React.FC<MetricData> = ({
  title,
  value,
  change,
  timeframe,
  trendData,
}) => (
  <div className="bg-white p-4 shadow-md">
    <div className="flex items-center">
      <div className="text-base sm:text-lg inter font-semibold text-[#2D2E2E] mb-2 mr-2">
        {title}
      </div>
      <span
        className={`text-sm ${
          change >= 0 ? "text-green-500" : "text-red-500"
        } flex items-center`}
      >
        {change >= 0 ? (
          <ArrowUpRight size={16} />
        ) : (
          <ArrowDownRight size={16} />
        )}
        {Math.abs(change)}%
      </span>
    </div>
    <div className="flex items-baseline justify-between">
      <div>
        <span className="text-2xl text-[#2E64E8] inter font-bold mr-2">
          {value}
        </span>
      </div>
    </div>
    <div className="flex mt-2">
      <div className="text-sm inter text-[#5A5D63]">{timeframe}</div>
      <div className="w-20 h-10">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={trendData.map((value, index) => ({ name: index, value }))}
          >
            <Line
              type="monotone"
              dataKey="value"
              stroke={change >= 0 ? "#34D399" : "#EF4444"}
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  </div>
);

// Sales Funnel Chart Component
const SalesFunnelChart: React.FC = () => (
  <ResponsiveContainer width="100%" height={300}>
    <LineChart data={salesFunnelData}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line type="monotone" dataKey="Orders" stroke="#8884d8" />
      <Line type="monotone" dataKey="Discounted Orders" stroke="#82ca9d" />
    </LineChart>
  </ResponsiveContainer>
);

// New vs Repeat Buyers Chart Component
const NewVsRepeatChart: React.FC = () => {
  const COLORS = ["#4338CA", "#34D399"];

  return (
    <ResponsiveContainer width="100%" height={300}>
      <PieChart>
        <Pie
          data={newVsRepeatData}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={100}
          fill="#8884d8"
          dataKey="value"
        >
          {newVsRepeatData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

// Product Table Component
const ProductTable: React.FC = () => {
  const [showProducts, setShowProducts] = useState(true);
  return (
    <div className="mt-8 sm:mt-12 rounded-md">
      <div className="bg-white shadow-md p-4 sm:p-4">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
          <div className="flex flex-col">
            <div className="mb-2 sm:mb-0 text-black">
              <button
                className={`px-3 py-1 sm:px-4 sm:py-2 border rounded-l-lg text-sm sm:text-base ${
                  showProducts ? "bg-gray-200" : "bg-white"
                }`}
                onClick={() => setShowProducts(true)}
              >
                Products
              </button>
              <button
                className={`px-3 py-1 sm:px-4 sm:py-2 border rounded-r-lg text-sm sm:text-base ${
                  !showProducts ? "bg-gray-200" : "bg-white"
                }`}
                onClick={() => setShowProducts(false)}
              >
                Marketing
              </button>
            </div>
            <div className="flex flex-col">
              <div className="text-lg mt-5 inter">
                {showProducts
                  ? "Top Performing Products"
                  : "Marketing Campaigns"}
              </div>
              <div className="text-sm text-[#5A5D63CC] inter">
                {showProducts
                  ? "Products with the highest sales and revenue"
                  : "Performance of recent marketing efforts"}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-200">
            <tr>
              {(showProducts ? productTableHeaders : marketingTableHeaders).map(
                (header, index) => (
                  <th
                    key={index}
                    className="px-4 sm:px-6 py-3 text-left text-sm font-medium inter text-gray-500 uppercase tracking-wider"
                  >
                    {header}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {showProducts
              ? productSalesData.map((product, index) => (
                  <tr key={index}>
                    <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm">
                      {product.name}
                    </td>
                    <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm">
                      {product.sales}
                    </td>
                    <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm">
                      {product.revenue}
                    </td>
                    <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm">
                      {product.action}
                    </td>
                  </tr>
                ))
              : marketingData.map((item, index) => (
                  <tr key={index}>
                    <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm">
                      {item.company}
                    </td>
                    <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm">
                      {item.clicks}
                    </td>
                    <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm">
                      {item.conversions}
                    </td>
                    <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm">
                      {item.revenue}
                    </td>
                    <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm">
                      {item.roi}
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
// Main Component
const Main: React.FC = () => {
  return (
    <main className="flex-1 overflow-y-auto">
      <h1 className="text-2xl font-bold mb-4">Hey, Rahul</h1>
      <p className="mb-6 text-gray-600">Here is all your analytics overview</p>

      <div className="grid grid-cols-4 mb-6">
        {metricCardsData.map((metric, index) => (
          <MetricCard key={index} {...metric} />
        ))}
      </div>

      <div className="flex gap-4 mb-6">
        <div className="w-2/3 bg-white p-4 inter text-left rounded shadow">
          <div className="font-bold mb-4 text-lg">Sales Funnel</div>
          <SalesFunnelChart />
        </div>
        <div className="w-1/3 bg-white p-4 text-center inter rounded shadow">
          <div className="font-bold text-lg mb-4">New Vs Repeat buyers</div>
          <NewVsRepeatChart />
        </div>
      </div>
      <ProductTable />
    </main>
  );
};

export default Main;
