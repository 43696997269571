import React, {useState} from 'react'
import { Feature } from '../constants/Feature';
import parseStyles from '../utils/ParsingFunction';

const FeatureItem = () => {
  const [activeTab, setActiveTab] = useState(1); // State to track the currently active tab

  return (
    <div className="flex flex-col rounded-lg feature text-left grotesk">
      <div className="heading text-left">Features</div> {/* Section heading */}
      <div className="tab-container bg-white w-[90vw]"> {/* Container for tabs */}
        <ul className="flex tabs item-start text-center justify-center onest text-base">
          {Feature.map((feature) => (
            <li
              key={feature.id}
              className={activeTab === feature.id ? "active-tab" : "w-1/4 cursor-pointer"} // Apply active class if tab is selected
              onClick={() => setActiveTab(feature.id)} // Set active tab on click
            >
              {feature.heading} {/* Tab heading */}
            </li>
          ))}
        </ul>
      </div>
      {Feature.map((data) => {
        if (data.id !== activeTab) return null; // Render only the content of the active tab
        const styles = parseStyles(data.style); // Parse styles for the feature image
        return (
          <div className="banner-feature flex justify-between w-[90vw] mt-[3rem]" key={data.id}>
            <div className="left pt-5">
              <div className="upper data flex items-center text-left">
                <img className="mr-5 h-8 w-8" src={data.logo} alt="" /> {/* Logo for the feature */}
                <div className="tab-heading text-3xl text-black text-left grotesk w-4/5">{data.heading}</div> {/* Feature heading */}
              </div>
              <div className="tab-content text-xl onest pl-3">{data.text}</div> {/* Feature description */}
            </div>
            <div className="flex w-1/2">
              <img className="why-img" src={data.img} alt="" style={styles} /> {/* Feature image with parsed styles */}
            </div>
          </div>
        );
      })}
    </div>
  )
}

export default FeatureItem