import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../utils/style.css";

import {
  categories,
  Category,
  companies,
  Company,
} from "../constants/Integrations"; // Adjust the path as per your actual file structure
import IntegrationBanner from "../components/IntegrationBanner";

const Integrations: React.FC = () => {
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]); // State to track selected categories
  const [searchQuery, setSearchQuery] = useState(""); // State to track search query

  // Function to handle category selection
  const handleCategoryChange = (categoryId: number) => {
    setSelectedCategories((prevSelected) =>
      prevSelected.includes(categoryId)
        ? prevSelected.filter((id) => id !== categoryId) // Remove category if already selected
        : [...prevSelected, categoryId] // Add category if not selected
    );
  };

  // Function to handle search input change
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value); // Update search query state
  };

  // Function to filter companies based on selected categories and search query
  const filterCompanies = (): Company[] => {
    let filteredCompanies = companies; // Start with all companies
    if (selectedCategories.length > 0) {
      // If there are selected categories, filter companies
      const selectedCompanyIds = selectedCategories.flatMap(
        (categoryId) =>
          categories.find((cat) => cat.id === categoryId)?.companyIds || [] // Get company IDs for selected categories
      );
      console.log("filterscd", filteredCompanies); // Debugging log
      filteredCompanies = filteredCompanies.filter((company) =>
        selectedCompanyIds.includes(company.id) // Filter companies by selected IDs
      );
    }

    if (searchQuery) {
      // If there is a search query, filter companies by name
      filteredCompanies = filteredCompanies.filter((company) =>
        company.name.toLowerCase().includes(searchQuery.toLowerCase()) // Case-insensitive search
      );
    }

    return filteredCompanies; // Return the filtered list of companies
  };

  const filteredCompanies = filterCompanies(); // Get the filtered companies based on current state
  return (
    <div className="integrations w-full">
      <Header backgroundStyle="background:linear-gradient(96.3deg, #FEF3ED 0%, #E8F4FF 100.39%)" /> {/* Header with background style */}
      <IntegrationBanner /> {/* Integration banner component */}
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-[30vw] bg-gray-100 p-4 overflow-y-auto md:sticky md:top-0 md:h-screen"> {/* Sidebar for categories and search */}
          <input
            type="text"
            placeholder="Search companies..."
            value={searchQuery} // Controlled input for search
            onChange={handleSearchChange} // Handle search input change
            className="mb-4 w-full md:w-4/5 p-2 border bg-transparent border-gray-300 rounded-xxl"
          />
          <h2 className="text-xl text-black font-bold mb-4 ml-4 onest">
            Categories
          </h2>
          <ul className="space-y-2">
            {categories.map((category: Category) => (
              <li key={category.id} className="flex items-center">
                <input
                  type="checkbox"
                  checked={selectedCategories.includes(category.id)} // Check if category is selected
                  onChange={() => handleCategoryChange(category.id)} // Handle category selection
                  className="mr-4 w-4 h-4 rounded-lg"
                />
                <span
                  className={`cursor-pointer ${
                    selectedCategories.includes(category.id)
                      ? "font-semibold" // Highlight selected category
                      : "text-[#52606D]"
                  }`}
                  onClick={() => handleCategoryChange(category.id)} // Handle category click
                >
                  {category.name} {/* Display category name */}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex-1 p-4 overflow-y-auto"> {/* Main content area for displaying companies */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8">
            {filteredCompanies.map((company: Company) => (
              <div
                key={company.id}
                className="group bg-white relative border border-gray-300 rounded-lg p-4 shadow transition-transform transform hover:scale-105 overflow-hidden" // Company card styling
              >
                <img
                  src={company.imageUrl} // Company logo/image
                  alt={company.name} // Alt text for accessibility
                  className="m-auto relative max-w-full top-[60px] align-middle h-24 object-contain transition-opacity duration-300 group-hover:opacity-0" // Image styling with hover effect
                />
                <h3 className="text-[#2F269D] grotesk text-lg md:text-xl pl-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 transform group-hover:-translate-y-20 mt-4">
                  {company.name} {/* Company name */}
                </h3>
                <p className="text-[#52606D] onest text-xs md:text-sm pl-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 transform group-hover:-translate-y-20 mt-4 ml-0">
                  {company.description} {/* Company description */}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer /> {/* Footer component */}
    </div>
  );
};
export default Integrations;

