// Separate notifications for users
export const adminNotifications = [
  {
    key: "dataAlerts",
    label: "Data Alerts",
    description: "Get notified about important data changes.",
  },
  {
    key: "productUpdates",
    label: "Product Updates",
    description: "Receive emails about new features and product updates.",
  },
  {
    key: "securityAlerts",
    label: "Security Alerts",
    description:"Get notified if there are any suspicious activities in your account.",
  },
  {
    key: "maintenanceNotices",
    label: "Maintenance Notices",
    description: "Receive updates about system maintenance and downtime.",
  },
];

// Separate notifications for admins
export const userNotifications = [
  {
    key: "userManagement",
    label: "User Management",
    description:
      "Get notifications when a user is added or removed from your workspace.",
  },
  {
    key: "dataSources",
    label: "Data Sources",
    description:"Receive alerts when data sources are added or removed from your workspace.",
  },
  {
    key: "productUpdates",
    label: "Product Updates",
    description: "Receive emails about new features and product updates.",
  },
  {
    key: "securityAlerts",
    label: "Security Alerts",
    description:
      "Get notified of suspicious activities affecting the entire system.",
  },
  {
    key: "dataAlerts",
    label: "Data Alerts",
    description: "Get notified about important data changes.",
  },
  {
    key: "maintenanceNotices",
    label: "Maintenance Notices",
    description: "Receive updates about system maintenance and downtime.",
  },
];
