import collection from "../assets/images/collection.png";
import processing from "../assets/images/processing.png";
import storage from "../assets/images/storage.png";
import ask from "../assets/images/ask.png";

import analystLogo11 from "../assets/images/AI Pricing/1.svg";
import analystLogo12 from "../assets/images/AI Pricing/2.svg";
import analystLogo13 from "../assets/images/AI Pricing/3.svg";
import analystLogo21 from "../assets/images/AI Pricing/4.svg";


export const Working = {
  working:[
    {
      "id": 1,
      "heading": "Collection",
      "text" : "The platform  gathers and collect large amounts of data from different sources",
      "alt" : "logo",
      "logo" : collection
    },
    {
        "id": 2,
        "heading": "Storage",
        "text": "The platform uses cloud-based storage to store the data collected from different sources",
        "alt" : "logo",
        "logo" : storage
    },
    {
        "id": 3,
        "heading": "Processing",
        "text": "The platform uses machine learning algorithms to process and analyze the data",
        "alt" : "logo",
        "logo" : processing
      },
      {
        "id": 4,
        "heading": "Ask",
        "text": "Ask questions in plain English and let AI give insights",
        "alt" : "logo",
        "logo" : ask
      }
    ],
    Aboutwhat:[
      {
        "id": 1,
        "heading": "Data Integration",
        "text" : "The platform gathers and collect large amounts of data from different sources",
        "alt" : "logo",
        "logo" : analystLogo11
      },
      {
          "id": 2,
          "heading": "Data Analytics",
          "text": "The platform uses cloud-based storage to store the data collected from different sources",
          "alt" : "logo",
          "logo" : analystLogo12
      },
      {
          "id": 3,
          "heading": "Business Intelligence",
          "text": "The platform uses machine learning algorithms to process and analyze the data",
          "alt" : "logo",
          "logo" : analystLogo13
        },
        {
          "id": 4,
          "heading": "Data Governance",
          "text": "Ask questions in plain English and let AI give insights",
          "alt" : "logo",
          "logo" : analystLogo21
        }
    ]
    }

  