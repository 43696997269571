import Header from "../components/Header";
import BannerItem from "../components/BannerItem";
import Signup from "../components/Signup";
import Integration from "../components/Integration";
import Footer from "../components/Footer";
import { Images } from "../constants/Images";
import { Banner } from "../constants/Banner";
import AiAnalystItem from "../components/AiAnalystItem";

const AiAnalystPage = () => {
  return (
    <div>
      <Header backgroundStyle="" />
      <BannerItem
        content={Banner.aianalyst.content}
        button = {Banner.aianalyst.button}
        page = ""
        image={Images.analystBanner}
        backgroundStyle="height: 80vh; background: linear-gradient(96.3deg, #FEF3ED 0%, #E8F4FF 100.39%);"
        imageStyle="width:495px; height:495px; position:absolute; right:5rem"
      />
      <AiAnalystItem />
      <Signup />
      <Integration />
      <Footer />
    </div>
  );
};

export default AiAnalystPage;
